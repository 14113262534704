import { Box, Grid, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TituloPantalla from "../Titulos/TituloPantalla";
import Canchas from "./Canchas";
import InformacionCliente from "./InformacionCliente";
import InformacionGeneral from "./InformacionGeneral";
import Predio from "./Predio";
const MenuClub = () => {
  const miClub = useSelector((state) => state.clubes.miClub);

  const [tabSelected, setTabSelected] = useState("general");

  const handleTabChange = (e, newValue) => {
    setTabSelected(newValue);
  };

  return (
    <>
      <Box className="main">
        <TituloPantalla titulo="Mi Club" />
        <Grid container direction="column" alignItems="center" spacing={20}>
          {/*<Grid
            item
            container
            direction="column"
            alignItems="center"
            spacing={8}
          >
            <Grid item>
              <Avatar
                src="/assets/logo.png"
                sx={{
                  width: "7em",
                  height: "7em",
                  border: 3,
                  borderColor: "common.principal",
                }}
              />
            </Grid>
            <Grid item>
              <Typography variant="h4" color="primary">
                {miClub.nombre}
              </Typography>
            </Grid>
          </Grid>*/}
          <Grid item container justifyContent="center">
            <Grid item xs={12}>
              <Tabs
                value={tabSelected}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="info club tabs"
                variant="fullWidth"
              >
                <Tab value="cliente" label="Información Cliente" />
                <Tab value="general" label="Información General" />
                <Tab value="club" label="Predio" />
                <Tab value="canchas" label="Canchas" />
              </Tabs>
            </Grid>
            <Grid item container alignContent={"center"}>
              {tabSelected === "cliente" ? <InformacionCliente /> : null}
              {tabSelected === "general" ? <InformacionGeneral /> : null}
              {tabSelected === "club" ? <Predio /> : null}
              {tabSelected === "canchas" ? <Canchas /> : null}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MenuClub;
