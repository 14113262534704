const INITIAL_STATE = {
  estadoAutorizacion: "pendiente",
  bloqueado: false,
  cargando: false,
  cargandoIniciarSesion: false,
  error: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SESION_INICIADA":
      return { ...state, estadoAutorizacion: "aprobado" };
    case "SESION_CERRADA":
      return { ...state, estadoAutorizacion: "rechazado" };
    case "USUARIO_BLOQUEADO":
      return { ...state, bloqueado: true };
    case "CARGANDO_AUTH":
      return { ...state, cargando: action.payload };
    case "CARGANDO_INICIAR_SESION":
      return { ...state, cargandoIniciarSesion: action.payload };
    case "AUTH_ERROR":
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
