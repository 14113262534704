import servidor from "../../api/servidor";
import { errorGlobal, errorLocal } from "../error";

export const getPersonal = () => async (dispatch) => {
  dispatch({ type: "PERSONAL_CARGANDO", payload: true });
  dispatch({ type: "PERSONAL_ERROR", payload: "" });

  try {
    const respuesta = await servidor.get("/v2/users/club?sort=fechaCreacion");

    dispatch({ type: "PERSONAL_DATA", payload: respuesta.data.data });
  } catch (err) {
    dispatch(errorLocal(err, "PERSONAL_ERROR"));
  }

  dispatch({ type: "PERSONAL_CARGANDO", payload: false });
};

export const crearUsuario = (data, callback) => async (dispatch) => {
  dispatch({ type: "PERSONAL_CARGANDO", payload: true });

  try {
    const respuesta = await servidor.post("/v2/users/club", data);

    callback(true);
  } catch (err) {
    dispatch(errorGlobal(err));
  }
  dispatch({ type: "PERSONAL_CARGANDO", payload: false });
};

export const editarUsuario = (id, data, callback) => async (dispatch) => {
  dispatch({ type: "PERSONAL_CARGANDO", payload: true });

  try {
    const respuesta = await servidor.patch(`/v2/users/${id}`, data);

    callback(true);
  } catch (err) {
    dispatch(errorGlobal(err));
  }
  dispatch({ type: "PERSONAL_CARGANDO", payload: false });
};

export const borrarUsuario = (id) => async (dispatch) => {
  dispatch({ type: "PERSONAL_CARGANDO", payload: true });

  try {
    await servidor.delete(`/v2/users/${id}`);

    dispatch({ type: "PERSONAL_BORRAR", payload: id });
  } catch (err) {
    dispatch(errorGlobal(err));
  }

  dispatch({ type: "PERSONAL_CARGANDO", payload: false });
};
