import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Container,
  FormControl,
  FormGroup,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { iniciarSesion, signInFirebase } from "../../actions/auth";
import { isValidEmail, isValidPass } from "../../Handle/Handle";
import Error from "../Alertas/Error";
import Logo from "../Logo/Logo";
const Login = () => {
  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cargandoIniciarSesion = useSelector(
    (state) => state.auth.cargandoIniciarSesion
  );
  const authError = useSelector((state) => state.auth.error);
  //states
  const [email, setEmail] = useState("");
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [errorOpen, setErrorOpen] = useState(false);

  const cookies = new Cookies();

  useEffect(() => {
    if (authError) {
      setErrorOpen(true);
    }
  }, [authError]);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleChangePass = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidEmail(email) && isValidPass(values.password)) {
      dispatch(
        iniciarSesion({ email, contraseña: values.password }, (exito) => {
          if (exito) {
            signInFirebase();
            navigate("/");
          }
        })
      );
    } else {
      return console.log("Usuario o contraseña no válidos");
    }
  };
  return (
    <>
      <Error
        open={errorOpen}
        setOpen={setErrorOpen}
        onClose={() => dispatch({ type: "AUTH_ERROR", payload: false })}
        texto="Ocurrió un error iniciando sesión... intentar de nuevo"
      />
      <Logo />
      <Container sx={{ mt: 30 }} maxWidth="sm">
        <h1 className="title">Iniciar Sesión</h1>
        <form action="">
          <FormGroup sx={{ mt: 30 }}>
            <TextField
              name="email"
              label="Email"
              variant="outlined"
              required
              value={email}
              onChange={handleChange}
            />
            {email === ""
              ? null
              : !isValidEmail(email) && (
                  <p className="mt-30 error">Formato de email invalido</p>
                )}
            <FormControl sx={{ mt: 30 }} variant="outlined">
              <InputLabel htmlFor="passwordRegister">Contraseña</InputLabel>
              <OutlinedInput
                id="passwordRegister"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChangePass("password")}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            {values.password === ""
              ? null
              : !isValidPass(values.password) && (
                  <p className="mt-10 error">Password invalida</p>
                )}
            <a
              href="https://wa.me/+5493516019304?text=Hola%20Flobi!%20Me%20olvidé%20mi%20contraseña"
              className="mt-30 mb-30 secondary"
            >
              <Typography variant="subTitle" color={"secondary"}>
                ¿Has olvidado tu Contraseña?
              </Typography>
            </a>
            <LoadingButton
              className="mt-30"
              sx={{ p: "1.5em" }}
              variant="contained"
              type="submit"
              loading={cargandoIniciarSesion}
              onClick={handleSubmit}
            >
              Iniciar Sesión
            </LoadingButton>
          </FormGroup>
        </form>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            m: 16,
          }}
        >
          <p>¿No estás registrado?</p>
          <Link to="/accountregister" className="secondary">
            Creá un cuenta
          </Link>
        </Box> */}
      </Container>
    </>
  );
};

export default Login;
