import firebase from "firebase";
 const app = firebase.initializeApp({
  apiKey: "AIzaSyCkt2ezsPoCS7zuYFul_dootoVfhMdvz-4",
  authDomain: "flobi-tenis.firebaseapp.com",
  databaseURL: "https://flobi-tenis-default-rtdb.firebaseio.com",
  projectId: "flobi-tenis",
  storageBucket: "flobi-tenis.appspot.com",
  messagingSenderId: "157814260958",
  appId: "1:157814260958:web:773b197f071889c6982e4f",
  measurementId: "G-GV6T8PFK62"
  });
  export default app;