import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import TituloPantalla from "../Titulos/TituloPantalla";
import { crearUsuario, editarUsuario } from "../../actions/personal";
import CreacionExitosa from "../Modals/CreacionExitosa";
import Error from "../Alertas/Error";

const permisos = [
  { label: "Reservas", value: "reservas" },
  { label: "Torneos", value: "torneos" },
  { label: "Facturación", value: "facturacion" },
  { label: "Configuración", value: "configuracion" },
  { label: "Personal", value: "staff" },
];

const FormularioStaff = ({ accion, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const error = useSelector((state) => state.personal.error);
  const seleccionado = useSelector((state) => state.personal.selected);

  const [values, setValues] = useState({
    contraseña: "",
    confirmarContraseña: "",
    mostrarContraseña: false,
    nombre: "",
    apellido: "",
    email: "",
    permisos: [],
  });

  const [mostrarModal, setMostrarModal] = useState(false);
  const [mostrarError, setMostrarError] = useState(false);

  const formRef = useRef(null);

  useDispatch(() => {
    return () => {
      dispatch({ type: "PERSONAL_SELECTED", payload: null });
    };
  }, []);

  useEffect(() => {
    if (accion === "editar") {
      setValues((prev) => ({
        ...prev,
        nombre: seleccionado.nombre,
        apellido: seleccionado.apellido,
        email: seleccionado.email,
        permisos: seleccionado.club.permisos,
      }));
    }

    if (error) {
      setMostrarError(true);
    } else {
      setMostrarError(false);
    }
  }, [mostrarError]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      if (!values.permisos.includes(event.target.name)) {
        setValues({
          ...values,
          permisos: [...values.permisos, event.target.name],
        });
      }
    } else {
      if (values.permisos.includes(event.target.name)) {
        setValues({
          ...values,
          permisos: values.permisos.filter(
            (permiso) => permiso !== event.target.name
          ),
        });
      }
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      mostrarContraseña: !values.mostrarContraseña,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseModal = () => {
    setMostrarModal(false);

    navigate("/personal");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validity = formRef.current.reportValidity();

    if (validity) {
      if (accion === "crear") {
        dispatch(
          crearUsuario(values, (exito) => {
            if (exito) {
              setMostrarModal(true);
            }
          })
        );
      } else {
        dispatch(
          editarUsuario(id, values, (exito) => {
            if (exito) {
              setMostrarModal(true);
            }
          })
        );
      }
    }
  };

  return (
    <>
      <CreacionExitosa
        open={mostrarModal}
        onClose={handleCloseModal}
        setOpen={handleCloseModal}
        titulo={
          accion === "crear"
            ? "Agregaste un miembro a tu club"
            : "Editaste a un miembro de tu club"
        }
        texto="Este usuario podrá acceder a las funcionalidades que le diste acceso con su email y contraseña"
      />
      <Error
        open={mostrarError}
        setOpen={setMostrarError}
        onClose={() => dispatch({ type: "PERSONAL_ERROR", payload: "" })}
        texto={error}
      />
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{ m: "2em 0 2em 0" }}
      >
        <form action="" ref={formRef}>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={24}
          >
            <Grid item xs={6}>
              <TextField
                name="name"
                label="Nombre"
                size="small"
                variant="outlined"
                onChange={handleChange("nombre")}
                value={values.nombre}
                required
                disabled={accion === "editar"}
                fullWidth
                placeholder="Maximo 30 caracteres"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="lastName"
                label="Apellido"
                size="small"
                variant="outlined"
                onChange={handleChange("apellido")}
                value={values.apellido}
                required
                disabled={accion === "editar"}
                fullWidth
                placeholder="Maximo 30 caracteres"
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            sx={{ m: "2em 0 2em 0" }}
            justifyContent="center"
          >
            <Grid item xs={6}>
              <TextField
                name="emailR"
                label="Email"
                size="small"
                variant="outlined"
                onChange={handleChange("email")}
                value={values.email}
                required
                disabled={accion === "editar"}
                fullWidth
              />
            </Grid>
          </Grid>
          {accion === "crear" ? (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              spacing={24}
            >
              <Grid item xs={6}>
                <FormControl sx={{ pr: 5 }} variant="outlined" size="small">
                  <InputLabel htmlFor="contraseñaUsuario">
                    Contraseña
                  </InputLabel>
                  <OutlinedInput
                    id="contraseñaUsuario"
                    type={values.mostrarContraseña ? "text" : "password"}
                    value={values.contraseña}
                    required
                    onChange={handleChange("contraseña")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.mostrarContraseña ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Contraseña"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ pr: 5 }} variant="outlined" size="small">
                  <InputLabel htmlFor="passwordRegister">
                    Confirmar Contraseña
                  </InputLabel>
                  <OutlinedInput
                    id="confirmarContraseña"
                    type={values.mostrarContraseña ? "text" : "password"}
                    value={values.confirmarContraseña}
                    required
                    onChange={handleChange("confirmarContraseña")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.mostrarContraseña ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirmar Contraseña"
                  />
                </FormControl>
              </Grid>
            </Grid>
          ) : null}

          {/* <Grid
            item
            container
            sx={{ m: "2em 0 2em 0" }}
            justifyContent="center"
          >
            <Grid item xs={6}>
              <FormControl sx={{ m: 1 }} fullWidth size="small">
                <InputLabel id="canchas">Rol</InputLabel>
                <Select
                  required
                  labelId="rol-label"
                  id="rol-select"
                  value={values.rol}
                  onChange={handleChange}
                  label="Rol"
                >
                  <MenuItem value="Administrador">Administrador</MenuItem>
                  <MenuItem value="Colaborador">Colaborador</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid> */}
          <Grid
            item
            container="column"
            alignItems="center"
            justifyContent="center"
            spacing={4}
            sx={{ m: "2em 0 2em 0" }}
          >
            <Grid item sx={{ mb: "1em" }}>
              <Typography className="title horario" variant="title2">
                Permisos
              </Typography>
              {/* <p className="title horario">Permisos</p> */}
            </Grid>
            <Grid item container justifyContent="center" alignItems="center">
              {permisos.map((permiso, i) => (
                <Grid item xs={4} container justifyContent="center">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.permisos.includes(permiso.value)}
                        />
                      }
                      label={permiso.label}
                      name={permiso.value}
                      onChange={handleCheckboxChange}
                    />
                  </FormGroup>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                type="submit"
                onClick={handleSubmit}
                sx={{
                  minWidth: "25em",
                  p: "1em",
                  mt: "1em",
                  "&:hover": { backgroundColor: "common.secondary.light" },
                }}
                variant="contained"
                color="secondary"
              >
                <Typography variant="title" color={"white"}>
                  {accion === "crear" ? "Crear Usuario" : "Editar Usuario"}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default FormularioStaff;
