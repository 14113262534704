import {
  Box,
  CircularProgress,
  Divider,
  Fade,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { signInFirebase } from "../../actions/auth";
import {
  insertMessage,
  myChats,
  updateChatInServer,
  updateListChat,
} from "../../actions/chats/index";
import { bucketURL } from "../../api/urls";
import app from "../../Firebase";
import Notice from "../Avisos/Notice";
import ChatList from "./ChatList";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import Messages from "./Messages";
import SearchInput from "./SearchInput";
import SendInput from "./SendInput";

const Chats = () => {
  //states
  const [recieverData, setRecieverData] = useState(null);
  const [chatId, setChatId] = useState();
  const [openChat, setOpenChat] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMore, setHasMore] = useState(allMessages.length > 0 ? true : false);
  const [pages, setPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isOrdering, setIsOrdering] = useState(false);
  const [login, setLogin] = useState(false);

  //redux
  const listChat = useSelector((state) => state.chats.chats);
  const error = useSelector((state) => state.chats.error);
  const etiqueta = useSelector((state) => state.chats.etiqueta);
  const isLoadingList = useSelector((state) => state.chats.cargando);
  const usuario = useSelector((state) => state.usuario.data);
  const club = useSelector((state) => state.clubes.miClub);
  const authFirebase = useSelector((state) => state.authFirebase);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(myChats(etiqueta));
  }, [etiqueta]);

  const dummyRef = useRef();
  const listChatRef = useRef();
  useEffect(() => {
    if (recieverData) {
      setAllMessages([]);
      setPages(1);
      setIsFetching(true);
      fetchMessages();
      setIsFetching(false);
      setHasMore(true);
    }
  }, [recieverData?.id]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };
  const sendMessage = async () => {
    if (chatMessage !== "") {
      let chatCopy = chatMessage;
      setChatMessage("");
      const NEW_MSG = {
        texto: chatCopy,
        audio: "",
        tipoMensaje: "texto",
        usuario: {
          _id: club._id,
          avatar: `${bucketURL}/${club.logo}`,
          name: `${club.nombre}`,
        },
      };
      try {
        setIsSending(true);
        insertMessage(chatId, NEW_MSG, async (exito) => {
          if (exito) {
            await dispatch(updateChatInServer(chatId));
            //orderChat();
            setIsOrdering(true);
            //dispatch(myChats(etiqueta))
          }
        });
        setHasMore(true);
        setIsSending(false);
        setIsOrdering(false);
        orderChat();
        dummyRef.current.scrollIntoView({ behavior: "smooth" });
        listChatRef.current.scrollIntoView({ behavior: "smooth" });
      } catch {
        console.log("error al enviar mensaje");
      }
    }
  };
  const orderChat = () => {
    let indice = listChat.findIndex((e) => e._id === chatId);
    let copia = [listChat[indice]];
    let unoMenos = listChat.filter((e) => e._id !== chatId);
    let nuevoArray = copia.concat(unoMenos);
    dispatch(updateListChat(nuevoArray));
  };
  const handleChatSelected = (userData, chatId) => {
    setRecieverData(userData);
    setChatId(chatId);
    setOpenChat(true);
    navigate(`/chats/${chatId}`);
  };
  const fetchMessages = () => {
    let chatRef = app.database().ref(`chats/${chatId}`);
    try {
      setIsFetching(true);
      chatRef
        .orderByChild("createdAt")
        .limitToLast(20)
        .on("value", (snapshot) => {
          let chatsNodo = [];
          snapshot.forEach((snap) => {
            chatsNodo.unshift(snap.val());
          });
          setAllMessages(chatsNodo);
          setPages(1);
          setIsFetching(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const fetchMore = () => {
    if (allMessages.length > 0 && allMessages.length / 20 !== pages) {
      setHasMore(false);
      return;
    }

    let chatRef = app.database().ref(`chats/${chatId}`);
    const ultimo = allMessages[allMessages.length - 1].createdAt;
    try {
      chatRef
        .orderByChild("createdAt")
        .limitToLast(21)
        .endAt(ultimo)
        .on("value", (snapshot) => {
          let moreMessages = [];

          snapshot.forEach((snap) => {
            if (snap.val().createdAt === ultimo) return;
            moreMessages.unshift(snap.val());
          });
          setAllMessages([...allMessages, ...moreMessages]);
          let newPage = pages + 1;
          setPages(newPage);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const left = {
    display: { xs: openChat ? "none" : "flex", sm: "flex" },
    flex: { xs: openChat ? 0.35 : 1, sm: 0.3 },
    minWidth: 280,
    borderRight: { xs: "none", sm: "solid" },
    borderRightColor: { xs: "none", sm: "#0000001f" },
    flexDirection: "column",
    backgroundColor: "#fff", //f9ede1
    height: "88vh",
  };
  const right = {
    height: "88vh",
    flexDirection: "column",
    backgroundColor: "#f0f2f5",
    display: { xs: openChat ? "flex" : "none" },
    flex: { xs: 1, sm: 0.7 },
  };

  return (
    <Box className="main">
      {authFirebase.error || !authFirebase.login ? (
        <Notice
          title={"Hubo un problema al cargar los chats. Intente de nuevo"}
          hasButton={true}
          titleButton={"Intentar de nuevo"}
          loading={authFirebase.cargando}
          onClick={() => dispatch(signInFirebase())}
        />
      ) : null}
      {authFirebase.login ? (
        <div style={container}>
          <Box sx={left}>
            <HeaderLeft
              photoURL={`${bucketURL}/${club.logo}`} //usuario?.fotoPerfil
            />
            {/* <Divider />

            <SearchInput
              setInputValue={(e) => setSearchValue(e)}
              inputValue={searchValue}
            /> */}
            <Divider />
            <div style={{ overflowY: "scroll" }}>
              <span ref={listChatRef}></span>
              {isLoadingList ? (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <CircularProgress color="warning" />
                </div>
              ) : error ? (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Typography h4>No hay chats abiertos.</Typography>
                </div>
              ) : (
                listChat?.map((e, index) => {
                  return (
                    <List key={index} dense sx={{ width: "100%", padding: 0 }}>
                      <ChatList
                        idChat={e._id}
                        srcAvatar={`${bucketURL}/${e.miembros[0].usuario.fotoPerfil}`}
                        title={`${e.miembros[0].usuario.nombre}`} //${e.miembros[0].usuario.apellido}
                        onClick={() =>
                          handleChatSelected(e.miembros[0].usuario, e._id)
                        }
                        selected={
                          e.miembros[0].usuario.id === recieverData?._id &&
                          openChat
                        }
                        order={isOrdering}
                      />
                    </List>
                  );
                })
              )}
            </div>
          </Box>
          {/*========RIGHT=========== */}
          {openChat && (
            <Fade in={openChat} unmountOnExit>
              <Box sx={right}>
                <HeaderRight
                  photoURL={`${bucketURL}/${recieverData.fotoPerfil}`}
                  username={`${recieverData.nombre} ${recieverData.apellido}`}
                  onClick={() => setOpenChat(false)}
                  onClick2={() => setOpenChat(false)}
                />
                <Divider />

                <div id="scrollableDiv" style={scroll}>
                  <InfiniteScroll
                    dataLength={allMessages.length}
                    next={fetchMore}
                    style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
                    inverse={true} //
                    hasMore={hasMore}
                    loader={<h4>loading</h4>}
                    scrollableTarget="scrollableDiv"
                  >
                    <span ref={dummyRef}></span>

                    {isSending && (
                      <div style={{ alignSelf: "center" }}>
                        <CircularProgress color="warning" />
                      </div>
                    )}
                    {allMessages.map((messages) => {
                      return (
                        <Messages
                          id={messages._id}
                          messages={messages}
                          currentUser={club?._id}
                          otherUser={messages.usuario._id}
                          allMessages={allMessages}
                          photoURL={`${bucketURL}/${recieverData.fotoPerfil}`}
                        />
                      );
                    })}
                    {isFetching && (
                      <div style={{ alignSelf: "center" }}>
                        <CircularProgress color="warning" />
                      </div>
                    )}
                  </InfiniteScroll>
                </div>
                <SendInput
                  inputValue={chatMessage}
                  setSendInput={(e) => setChatMessage(e)}
                  onClick={sendMessage}
                  onKeyDown={handleKeyDown}
                />
              </Box>
            </Fade>
          )}
        </div>
      ) : null}
    </Box>
  );
};
//styles
const container = {
  display: "flex",
  flexDirection: "row",
  flex: 1,
  width: "100%",
  backgroundColor: "#fff", //f0f2f5
  height: "100%",
  overflowY: "hidden",
};
const scroll = {
  height: "100vh",
  overflow: "scroll",
  display: "flex",
  flexDirection: "column-reverse",
  backgroundColor: "#fff",
};

export default Chats;
