import servidor from "../../api/servidor";
import { TYPES } from "../../reducers/authFirebaseReducer";
import app from "../../Firebase";
import { errorGlobal } from "../error";

export const signInFirebase = () => async (dispatch) => {
  dispatch({ type: TYPES.FIREBASE_LOADING, payload: true });
  dispatch({ type: TYPES.FIREBASE_ERROR, payload: false });
  try {
    await app.auth().signInAnonymously();
    dispatch({ type: TYPES.FIREBASE_AUTH, payload: true });
  } catch (err) {
    dispatch({ type: TYPES.FIREBASE_ERROR, payload: true });
  }
  dispatch({ type: TYPES.FIREBASE_LOADING, payload: false });
};

export const iniciarSesion = ({ email, contraseña }, callback) => async (
  dispatch
) => {
  dispatch({ type: "CARGANDO_INICIAR_SESION", payload: true });
  dispatch({ type: "AUTH_ERROR", payload: false });

  try {
    const respuesta = await servidor.post("/v2/users/login/password", {
      email,
      contraseña,
    });

    const { club } = respuesta.data.user;

    if (club) {
      dispatch({
        type: "PERMISOS_DATA",
        payload: club.permisos,
      });

      if (club.club) {
        dispatch({
          type: "CLUBES_MI_CLUB",
          payload: club.club,
        });
        dispatch({
          type: "CLIENTE_DATA",
          payload: club.club.cliente,
        });
      }
    }

    const usuario = {
      ...respuesta.data.user,
      club: undefined,
      tenis: undefined,
      padel: undefined,
    };

    dispatch({ type: "USUARIO_DATA", payload: usuario });

    dispatch({ type: "SESION_INICIADA" });

    callback(true);
  } catch (err) {
    dispatch({ type: "AUTH_ERROR", payload: true });
  }

  dispatch({ type: "CARGANDO_INICIAR_SESION", payload: false });
};

export const registrarse = (data, callback) => async (dispatch) => {
  dispatch({ type: "CARGANDO_REGISTRARSE", payload: true });
  dispatch({ type: "AUTH_ERROR", payload: false });

  try {
    const respuesta = await servidor.post("/usuarios/club/registrarse", data);

    //dispatch
    // dispatch({ type: "SESION_INICIADA", payload: true });

    callback(true);
  } catch (err) {
    console.log(err.response);
    dispatch({ type: "AUTH_ERROR", payload: true });
  }

  dispatch({ type: "CARGANDO_REGISTRARSE", payload: false });
};

export const cerrarSesion = (callback) => async (dispatch) => {
  dispatch({ type: "CARGANDO_AUTH", payload: true });

  try {
    const respuesta = await servidor.get("/v2/users/logout");

    dispatch({ type: "SESION_CERRADA" });

    callback(true);
  } catch (err) {
    dispatch(errorGlobal(err));
  }

  dispatch({ type: "CARGANDO_AUTH", payload: false });
};
