import { Avatar } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showLastMessage } from "../../actions/chats";
import ChatInfo from "./ChatInfo";

const ChatList = ({ srcAvatar, title, onClick, selected, idChat, order }) => {
  const dispatch = useDispatch();
  const [chatMessage, setChatMessage] = useState("");
  useEffect(() => {
    dispatch(
      showLastMessage(idChat, (msg) => {
        if (msg) {
          setChatMessage(msg);
        }
      })
    );
  }, [order]);
  const timeFormated = (TIMESTAMP) => {
    const date = new Date(TIMESTAMP);
    const hour = date.getHours() === 0 ? "00" : date.getHours();
    const minutes =
      date.getMinutes() === 0
        ? "00"
        : date.getMinutes() < 10
        ? `0${date.getMinutes()}`
        : date.getMinutes();
    const formated = `${hour}:${minutes}`;
    return formated;
  };

  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={onClick}
        selected={selected}
        sx={listStyle}
        color={"secondary"}
      >
        <ListItemAvatar>
          <Avatar src={srcAvatar} />
        </ListItemAvatar>
        <ChatInfo
          name={title}
          message={chatMessage}
          date={
            chatMessage.createdAt
              ? new Date(chatMessage.createdAt).toLocaleDateString()
              : ""
          }
          time={
            chatMessage.createdAt ? timeFormated(chatMessage.createdAt) : ""
          }
        />
      </ListItemButton>
    </ListItem>
  );
};
const listStyle = {
  padding: 5,
};

export default ChatList;
