import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TodayIcon from "@mui/icons-material/Today";
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { format } from "date-fns";
import { addHours } from "date-fns/esm";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { multipleTurnos } from "../../actions/turnos";
import AddTurno from "../algo/AddTurno";
import CustomModal from "../Modals/CustomModal";

const ScheduleModal = ({
  openModal,
  setOpenModal,
  selected,
  diaSelected,
  modal,
}) => {
  const dispatch = useDispatch();
  //redux
  const turnos = useSelector((state) => state.turnos.data);
  const loading = useSelector((state) => state.turnos.cargando);
  //state
  const [horariosState, setHorariosState] = useState([]);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [addMore, setAddMore] = useState(false);
  const [turnosReduce, setTurnosReduce] = useState([]); //Horarios de redux luego de pasar por el reduce

  useEffect(() => {
    const data = turnos.reduce(
      (acc, item) =>
        item.cancha === selected._id
          ? (acc = [
              ...acc,
              {
                fechaInicio: item.fechaInicio,
                fechaFinal: item.fechaFinal,
                cancha: item.cancha,
                precio: item.precio,
                deporte: item.deporte,
              },
            ])
          : acc,
      []
    );
    setTurnosReduce(data);
  }, [selected, turnos, openModal === false]);

  const deleteItem = (i) => {
    setOpenCollapse(false);
    const newArray = horariosState.filter((e, j) => j !== i);
    setHorariosState(newArray);
  };

  const addItemToArray = () => {
    setAddMore(!addMore);
  };

  const saveData = () => {
    if (horariosState.length > 0) {
      dispatch(
        multipleTurnos(horariosState, (exito) => {
          if (exito) {
            modal(true);
            setOpenModal(false);
            setHorariosState([]);
          }
        })
      );
    } else {
      setOpenModal(false);
    }
  };

  const onClickCancel = () => {
    setHorariosState([]);
    setAddMore(false);
  };

  const index = horariosState?.length - 1;

  const lastTime =
    index < 0
      ? format(addHours(new Date(), 1), "k:00")
      : format(new Date(horariosState[index].fechaFinal), "k:mm");

  return (
    <CustomModal
      showModal={openModal}
      setShowModal={setOpenModal}
      title={"Agregar horarios"}
      apply={addMore ? false : saveData}
      onClickCancel={onClickCancel}
      body={
        <>
          <Box sx={root}>
            {addMore ? (
              <AddTurno
                addMore={addMore}
                setAddMore={setAddMore}
                lastTime={lastTime}
                cancha={selected}
                array={horariosState}
                setArray={setHorariosState}
                diaSelected={diaSelected}
                turnosRedux={turnosReduce}
              />
            ) : null}
            {!addMore ? (
              <>
                {horariosState?.map((e, i) => {
                  console.log(e);
                  return (
                    <Box sx={bodyContainer}>
                      {/**LEFT */}
                      {/**List items */}
                      <List disablePadding sx={{ width: "90%" }}>
                        <ListItemButton
                          onClick={() => {
                            if (openCollapse === i) {
                              return setOpenCollapse(false);
                            }
                            return setOpenCollapse(i);
                          }}
                        >
                          <ListItemIcon>
                            <TodayIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${format(
                              new Date(e.fechaInicio),
                              "k:mm"
                            )} - ${format(new Date(e.fechaFinal), "k:mm")}`}
                          />
                          {openCollapse ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>

                        {/**Detail */}
                        <Collapse
                          in={openCollapse === i}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <ListItemButton
                              sx={{ ":hover": { cursor: "default" } }}
                            >
                              <ListItemIcon>
                                <MonetizationOnIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={e.precio}
                                sx={{ textAlign: "center" }}
                              />
                              <ListItemIcon />
                            </ListItemButton>
                          </List>
                        </Collapse>
                      </List>
                      {/**RIGHT */}
                      <Box sx={{ margin: "auto", width: "10%" }}>
                        <IconButton
                          color="secondary"
                          onClick={() => deleteItem(i)}
                        >
                          <DoDisturbOnIcon sx={{ fontSize: "1.5em" }} />
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })}
              </>
            ) : null}
            {/**Feet */}
            <Box sx={{ margin: "auto" }}>
              <IconButton
                color={addMore ? "secondary" : "primary"}
                //disabled={}
                onClick={addItemToArray}
              >
                {addMore ? (
                  <RemoveCircleIcon sx={{ fontSize: "3em" }} />
                ) : (
                  <AddCircleIcon sx={{ fontSize: "3em" }} />
                )}
              </IconButton>
            </Box>
            {loading && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </>
      }
    />
  );
};
const root = {
  display: "flex",
  flexDirection: "column",
  width: { xs: "100%", sm: "30em" },
};
const bodyContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

export default ScheduleModal;
