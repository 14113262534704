import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import deLocale from "date-fns/locale/es";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { primeraLetraMayuscula } from "../../Handle/Handle";

const ModalFilter = ({
  setShowModal,
  showModal,
  enableReservations,
  time,
  setTime,
  slot,
  setSlot,
  type,
  setType,
  price,
  setPrice,
  handleUpdate,
}) => {
  //redux
  const dispatch = useDispatch();
  const club = useSelector((state) => state.clubes.miClub);
  const cargando = useSelector((state) => state.turnos.cargando);
  //state
  const [checked, setChecked] = useState(false);
  const [fetched, setFetched] = useState(false);
  //effect
  useEffect(() => {
    if (fetched && !cargando) {
      setFetched(false);
      setShowModal(false);
    }
  }, [fetched, cargando]);
  const estadoReserva = ["disponible", "reservado"];

  return (
    <Dialog open={showModal} onClose={() => setShowModal(false)}>
      <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
        Elige tus filtros
      </DialogTitle>

      <DialogContent>
        {/* <Box sx={pushTop}>
          <Typography sx={centerText}>Hora Inicio</Typography>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={deLocale}
          >
            <MobileTimePicker
              name="horaInicio"
              required
              value={time}
              minutesStep={30}
              disabled={!checked}
              onChange={(newValue) => {
                setTime(newValue);
              }}
              renderInput={(params) => (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <TextField {...params} size="small" sx={{ width: "100%" }} />
                  <Checkbox
                    value={checked}
                    onClick={() => setChecked(!checked)}
                  />
                </Box>
              )}
            />
          </LocalizationProvider>
        </Box> */}
        <Box sx={pushTop}>
          <Typography sx={centerText}>Canchas</Typography>
          <FormControl fullWidth sx={{ minWidth: 230 }} size="small">
            <Select
              name="cancha"
              required
              labelId="cancha-label"
              id="cancha-select"
              value={slot || "Todas"}
              onChange={(e) => setSlot(e.target.value)}
            >
              {club.canchas.map((c, i) => (
                <MenuItem key={i} value={JSON.stringify(c)}>
                  {primeraLetraMayuscula(c.nombre)} -
                  {primeraLetraMayuscula(c.deporte)}
                </MenuItem>
              ))}
              <MenuItem key={club.canchas.length} value={"Todas"}>
                Todas
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={pushTop}>
          <Typography sx={centerText}>Precio</Typography>

          <Slider
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            min={500}
            max={10000}
            defaultValue={[3000, 7000]}
            disableSwap
          />
        </Box>
        <Box sx={pricesStyle}>
          <Typography>$ {price[0]}</Typography>
          <Typography>$ {price[1]}</Typography>
        </Box>
        {enableReservations ? (
          <Box sx={pushTop}>
            <Typography sx={centerText}>Estado reserva</Typography>

            <FormControl fullWidth sx={{ minWidth: 230 }} size="small">
              <Select
                name="estado"
                required
                labelId="estado-label"
                id="estado-select"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {estadoReserva.map((c, i) => (
                  <MenuItem key={i} value={c}>
                    {primeraLetraMayuscula(c)}
                  </MenuItem>
                ))}
                <MenuItem key={estadoReserva.length} value={""}>
                  Todos
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        ) : null}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          {cargando ? <CircularProgress /> : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={cargando}
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          disabled={cargando}
          onClick={() => {
            handleUpdate();
            setFetched(true);
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const centerText = {
  display: "flex",
  justifyContent: "center",
  marginBottom: 5,
};
const pricesStyle = {
  display: "flex",
  justifyContent: "space-between",
};
const pushTop = {
  marginTop: 10,
};

export default ModalFilter;
