import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Grid, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Navigate, Outlet } from "react-router-dom";
import ResolverAuth from "../Auth/ResolverAuth";
import DropdownIcon from "../Dropdown/DropdownIcon";
import SideBar from "../SideBar/SideBar";
import { useWindowsSize } from "../../utils/helperFunctions";
import app from "../../Firebase";
import { cerrarSesion } from "../../actions/auth";
import { TYPES } from "../../reducers/authFirebaseReducer";
const Header = () => {
  //redux
  const estadoAutorizacion = useSelector(
    (state) => state.auth.estadoAutorizacion
  );
  const cargandoAuth = useSelector((state) => state.auth.cargando);
  const miClub = useSelector((state) => state.clubes.miClub);
  //
  const size = useWindowsSize();
  const array = ["Configuración", "Cerrar Sesión"];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = async () => {
    try {
      await app.auth().signOut();
      dispatch({ type: TYPES.FIREBASE_AUTH, payload: false });
      dispatch(
        cerrarSesion((success) => {
          if (success) {
            navigate("/login");
          }
        })
      );
    } catch (err) {
      console.log("error al desloguear firebase ", err);
    }
  };

  const selectedOption = (event) => {
    switch (event) {
      case "Configuración":
        navigate("/mi-club");
      case "Cerrar Sesión":
        logOut();
      //do something
      //case "Eliminar Cuenta":
      //do something
      default:
      // do default something
    }
  };

  if (estadoAutorizacion === "pendiente") {
    return <ResolverAuth />;
  }

  if (estadoAutorizacion === "rechazado") {
    return <Navigate to="/login" />;
  }

  if (!miClub) {
    return <Navigate to="/clubes" />;
  }

  if (cargandoAuth) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100vh" }}
      >
        <CircularProgress color="primary" />
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <SideBar />
        </Grid>
        {/**LOGO FLOBI */}
        <Grid item xs={4} sx={{ textAlign: "-webkit-center" }}>
          <Box sx={{ maxWidth: "100px" }}>
            <a href="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 717.08 250.68"
              >
                <g id="Capa_2" data-name="Capa 2">
                  <g id="Capa_1-2" data-name="Capa 1">
                    <path
                      fill="#FF6934"
                      d="M352.22,209.18V99.53q0-12.75,7.21-20t20-7.21h25.49V90.72H383.33a9.35,9.35,0,0,0-6.93,2.62,9.62,9.62,0,0,0-2.63,7.12v21.43H401v18.37H373.77v68.92Zm68-1h21.52V70.56H420.25Zm89.27,2.26a50.54,50.54,0,0,0,26-6.69,50.6,50.6,0,0,0,0-87.26,54.3,54.3,0,0,0-52.22,0,51.35,51.35,0,0,0-18.06,69.17,49.42,49.42,0,0,0,18.06,18.09,50.84,50.84,0,0,0,26.2,6.69m0-18.47a27.45,27.45,0,0,1-15.44-4.24A29,29,0,0,1,484,176.19,37.38,37.38,0,0,1,484,144a29,29,0,0,1,10.11-11.5,30.07,30.07,0,0,1,30.79,0A28.2,28.2,0,0,1,535,144a38.23,38.23,0,0,1,0,32.22,28.2,28.2,0,0,1-10.1,11.5,27.61,27.61,0,0,1-15.35,4.24M625.93,210.4a55.41,55.41,0,0,0,20.77-3.68,42.33,42.33,0,0,0,15.63-10.64,48.23,48.23,0,0,0,9.92-16.49,61,61,0,0,0,3.46-21q0-14.72-5.89-25.64a43.18,43.18,0,0,0-16.28-17,46.53,46.53,0,0,0-24-6.13,38.66,38.66,0,0,0-12.73,2.07,37.86,37.86,0,0,0-10.85,5.85,31.06,31.06,0,0,0-7.87,8.85h-.18v-56H576.33V159q0,15.83,6.27,27.42a43.58,43.58,0,0,0,17.5,17.81q11.24,6.23,25.83,6.22m0-18.47a26.27,26.27,0,0,1-14.41-4.05,27.42,27.42,0,0,1-9.92-11.22,40.39,40.39,0,0,1,0-33.16,27.48,27.48,0,0,1,9.92-11.22,26.58,26.58,0,0,1,14.6-4.05,25.71,25.71,0,0,1,14.5,4.15,28.31,28.31,0,0,1,9.83,11.31A36.38,36.38,0,0,1,654,160.08a36.82,36.82,0,0,1-3.55,16.58,27.42,27.42,0,0,1-9.92,11.22,26.6,26.6,0,0,1-14.6,4.05m77.3-93.29a13.18,13.18,0,0,0,9.82-4.14,13.93,13.93,0,0,0,0-19.6,13.6,13.6,0,0,0-19.56,0,13.72,13.72,0,0,0,0,19.6,13.29,13.29,0,0,0,9.74,4.14m-10.67,109.5h21.52V112H692.56ZM263.75,37c-.21-49.35-75.25-49.35-75.48,0,.22,49.36,75.26,49.36,75.48,0M224.1,197.27c21.16,21.54,54.89,16,72.71-6.87L191.63,85.23c-21.17-21.54-54.89-16-72.71,6.87L224.1,197.27M208.94,211,192.12,194.2l-31.3-31.3q-8.67-8.68-17.23-17.47c-5.21-5.33-10.19-10.9-16.67-14.72a47.33,47.33,0,0,0-25.16-6.9,39.45,39.45,0,0,0-27,12.12L0,210.7l39.37,39.37L104,185.43l65.26,65.25Z"
                    />
                  </g>
                </g>
              </svg>
            </a>
          </Box>
        </Grid>
        {/**RIGHT TOP MENU */}
        <Grid item xs={4}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Box sx={iconContainer}>
              <DropdownIcon
                array={array}
                setValue={(e) => selectedOption(e)}
                icon={
                  <IconButton>
                    {size.width > 899 ? (
                      <AccountCircleIcon
                        sx={{ fontSize: "2em" }}
                        color="primary"
                      />
                    ) : (
                      <MoreVertIcon
                        sx={{ fontSize: "1.5em" }}
                        color="primary"
                      />
                    )}
                  </IconButton>
                }
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Outlet />
    </>
  );
};

const iconContainer = {
  width: "30px",
  mx: 5,
};

export default Header;
