import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TodayIcon from "@mui/icons-material/Today";
import {
  CircularProgress,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addHours } from "date-fns";
import deLocale from "date-fns/locale/es";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actualizarClub } from "../../actions/clubes";
import { getDayName, organizeDays } from "../../utils/helperFunctions";
import CustomModal from "../Modals/CustomModal";
import AddDay from "./AddDay";

const ChangeTimeModal = ({ showModal, setShowModal, arrayData }) => {
  //state
  const [open, setOpen] = useState();
  const [schedule, setSchedule] = useState(organizeDays(arrayData));
  const [addMore, setAddMore] = useState(false);
  const [fetched, setFetched] = useState(false);
  //redux
  const dispatch = useDispatch();
  const cargando = useSelector((state) => state.clubes.cargando);

  useEffect(() => {
    if (showModal === false) {
      setAddMore(false);
    }

    if (fetched && !cargando) {
      setFetched(false);
      setShowModal(false);
    }
  }, [showModal, fetched, cargando]);

  const handleChange = (t, i) => {
    let hour = new Date(t).getHours();
    let minutes = new Date(t).getMinutes();
    const copySchedule = [...schedule];
    copySchedule[i] = {
      ...copySchedule[i],
      inicio: { hora: hour, minutos: minutes },
    };
    setSchedule(copySchedule);
  };
  const handleChange2 = (t, i) => {
    let hour = new Date(t).getHours();
    let minutes = new Date(t).getMinutes();
    const copySchedule = [...schedule];
    copySchedule[i] = {
      ...copySchedule[i],
      final: { hora: hour, minutos: minutes },
    };
    setSchedule(copySchedule);
  };
  const deleteItem = (e) => {
    setOpen(false);
    const newArray = schedule.filter((j) => j.dia !== e.dia);
    setSchedule(newArray);
  };
  const handleAddDay = () => {
    setOpen(false);
    setAddMore(!addMore);
  };
  const handleUpdate = async () => {
    dispatch(actualizarClub({ horariosArray: schedule }));
    setFetched(true);
  };
  return (
    <CustomModal
      showModal={showModal}
      setShowModal={setShowModal}
      title={"Cambiar horarios de tu club"}
      disabled={cargando}
      apply={handleUpdate}
      body={
        <>
          <Box sx={container}>
            {addMore ? (
              <AddDay
                addMore={addMore}
                setAddMore={setAddMore}
                arrayData={schedule}
                setArrayData={setSchedule}
              />
            ) : null}

            {!addMore &&
              schedule.map((e, i) => {
                let horaInicio = schedule.find((f) => f.dia === e.dia).inicio
                  .hora;
                let minutosInicio = schedule.find((f) => f.dia === e.dia).inicio
                  .minutos;
                let horaFinal = schedule.find((f) => f.dia === e.dia).final
                  .hora;
                let minutosFinal = schedule.find((f) => f.dia === e.dia).final
                  .minutos;
                const timeInicio = new Date(
                  "",
                  "",
                  "",
                  horaInicio,
                  minutosInicio
                );
                const timeFinal = new Date("", "", "", horaFinal, minutosFinal);
                return (
                  <Box sx={bodyContainer} key={e.dia}>
                    {/**===LIST ITEMS=== */}
                    <List disablePadding sx={{ width: "85%" }}>
                      <ListItemButton
                        onClick={() => {
                          if (open === i) {
                            return setOpen(false);
                          }
                          return setOpen(i);
                        }}
                      >
                        <ListItemIcon>
                          <TodayIcon />
                        </ListItemIcon>
                        <ListItemText primary={getDayName(e.dia)} />
                        {open === i ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>

                      {/**===DETAIL=== */}
                      <Collapse in={open === i} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem sx={{ ml: 10 }}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={deLocale}
                            >
                              <MobileTimePicker
                                name="horaInicio"
                                required
                                label="Inicio"
                                value={timeInicio}
                                minTime={new Date("", "", "", 6, 0)}
                                minutesStep={30}
                                onChange={(t) => handleChange(t, i)}
                                renderInput={(params) => (
                                  <TextField {...params} size="small" />
                                )}
                              />
                            </LocalizationProvider>
                          </ListItem>
                          <ListItem sx={{ ml: 10 }}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={deLocale}
                            >
                              <MobileTimePicker
                                name="horaInicio"
                                required
                                label="Final"
                                value={timeFinal}
                                minTime={addHours(timeInicio, 1)}
                                maxTime={new Date("", "", "", 23, 0)}
                                minutesStep={30}
                                onChange={(t) => handleChange2(t, i)}
                                renderInput={(params) => (
                                  <TextField {...params} size="small" />
                                )}
                              />
                            </LocalizationProvider>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>
                    {/**===DELETE ITEM ===*/}
                    <Box sx={{ margin: "auto" }}>
                      <IconButton
                        color="secondary"
                        onClick={() => deleteItem(e)}
                      >
                        <DoDisturbOnIcon sx={{ fontSize: "1.5em" }} />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
            {/**====FEET===== */}
            <Box sx={{ margin: "auto" }}>
              <IconButton
                color={addMore ? "secondary" : "primary"}
                disabled={schedule.length > 6}
                onClick={handleAddDay}
              >
                {addMore ? (
                  <RemoveCircleIcon sx={{ fontSize: "3em" }} />
                ) : (
                  <AddCircleIcon sx={{ fontSize: "3em" }} />
                )}
              </IconButton>
            </Box>
            {cargando ? <CircularProgress sx={{ margin: "auto" }} /> : null}
          </Box>
        </>
      }
    />
  );
};
const container = {
  display: "flex",
  flexDirection: "column",
  width: "30em",
};
const bodyContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};
export default ChangeTimeModal;
