import React, { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { miCuenta } from "../../actions/usuario";
import { iniciarSesion, signInFirebase } from "../../actions/auth";
import app from "../../Firebase";
import Header from "../Header/Header";

const ResolverAuth = () => {
  const dispatch = useDispatch();
  const estadoAutorizacion = useSelector(
    (state) => state.auth.estadoAutorizacion
  );

  useEffect(() => {
    dispatch(miCuenta());
    dispatch(signInFirebase());
  }, [estadoAutorizacion]);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100vh" }}
      >
        <CircularProgress color="primary" />
      </Grid>
    </>
  );
};

export default ResolverAuth;
