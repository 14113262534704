import EditIcon from "@mui/icons-material/Edit";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Box,
  Button,
  IconButton,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import LayoutTabs from "./LayoutTabs";
import CustomModal from "../Modals/CustomModal";
import TabRow from "./TabRow";
import { useDispatch, useSelector } from "react-redux";
import { getCheckout } from "../../actions/cliente";

const InformacionCliente = () => {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.cliente.session);
  const cargandoCheckout = useSelector(
    (state) => state.cliente.cargandoCheckout
  );
  const cliente = useSelector((state) => state.cliente.data);
  const [showModal, setShowModal] = useState(false);
  const [newValue, setNewValue] = useState("");

  const handleCheckout = () => {
    dispatch(getCheckout());
  };

  useEffect(() => {
    if (session && session.url) {
      window.location.href = session.url;
      dispatch({ type: "CLIENTE_CHECKOUT_SESSION", payload: null });
    }
  }, [session]);

  return (
    <>
      <CustomModal
        setShowModal={setShowModal}
        showModal={showModal}
        title={"Cambiar método de pago"}
        body={
          <TextField
            sx={{ display: "flex" }}
            id="outlined-multiline-static"
            multiline
            rows={4}
            size={"small"}
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
        }
        // apply={() => {}}
      />

      <LayoutTabs>
        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>Nombre</Typography>
          }
          centerComponent={
            <Typography color={"#424242"}>{cliente.nombre} </Typography>
          }
        />
        <div style={divider} />
        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>E-mail</Typography>
          }
          centerComponent={
            <Typography color={"#424242"}> {cliente?.email} </Typography>
          }
        />
        <div style={divider} />

        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>Teléfono</Typography>
          }
          centerComponent={
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                mb={2}
              >
                <PhoneIcon sx={phoneStyle} />
                <Typography> {cliente?.telefono} </Typography>
              </Box>
            </Box>
          }
        />
        <div style={divider} />

        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>
              Identificación Fiscal
            </Typography>
          }
          centerComponent={
            <Typography color={"#424242"}>
              {cliente?.identificacionFiscal}{" "}
            </Typography>
          }
        />
        <div style={divider} />
        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>Moneda</Typography>
          }
          centerComponent={
            <Typography color={"#424242"}>{cliente?.moneda}</Typography>
          }
        />
        <div style={divider} />

        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>
              Método de pago activo
            </Typography>
          }
          centerComponent={
            cliente?.metodoPagoActivado === true ? (
              cargandoCheckout ? (
                <CircularProgress />
              ) : (
                <Typography color={"#424242"}>
                  Cambiar Método de pago
                </Typography>
              )
            ) : (
              <LoadingButton
                variant="contained"
                onClick={handleCheckout}
                loading={cargandoCheckout}
              >
                Activar método de pago
              </LoadingButton>
            )
          }
          rightComponent={
            cliente?.metodoPagoActivado === true ? (
              <IconButton
                onClick={() => {
                  setNewValue(
                    "Dirígete al siguiente link para poder cambiar tu método de pago https://flobi.com.ar"
                  );
                  setShowModal(true);
                }}
              >
                <EditIcon />
              </IconButton>
            ) : null
          }
        />
      </LayoutTabs>
    </>
  );
};
const divider = {
  width: "100%",
  height: 1,
  backgroundColor: "#DDDDDD",
  margin: 10,
};
const phoneStyle = {
  backgroundColor: "#f5f5f5",
  borderRadius: 1,
  padding: 3,
  mr: 3,
};
export default InformacionCliente;
