import { combineReducers } from "redux";
import authReducer from "./authReducer";
import usuarioReducer from "./usuarioReducer";
import clubesReducer from "./clubesReducer";
import formularioClubReducer from "./formularioClubReducer";
import reclamosReducer from "./reclamosReducer";
import turnosReducer from "./turnosReducer";
import personalReducer from "./personalReducer";
import torneosReducer from "./torneosReducer";
import chatsReducer from "./chatsReducer";
import clienteReducer from "./clienteReducer";
import permisosReducer from "./permisosReducer";
import pruebaReducer from "./pruebaReducer";
import facturacionReducer from "./facturacionReducer";
import errorReducer from "./errorReducer";
import authFirebase from "./authFirebaseReducer";

export default combineReducers({
  auth: authReducer,
  usuario: usuarioReducer,
  clubes: clubesReducer,
  formularioClub: formularioClubReducer,
  reclamos: reclamosReducer,
  turnos: turnosReducer,
  personal: personalReducer,
  torneos: torneosReducer,
  chats: chatsReducer,
  cliente: clienteReducer,
  permisos: permisosReducer,
  prueba: pruebaReducer,
  facturacion: facturacionReducer,
  error: errorReducer,
  authFirebase: authFirebase,
});
