import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import useInterceptors from "./hooks/useInterceptors";
import servidor from "./api/servidor";
//Import component
import ProtectedRoute from "./Components/ProtectedRoutes/ProtectedRoute";
import AuthRoutes from "./Components/ProtectedRoutes/AuthRoutes";
import PermisosProtected from "./Components/ProtectedRoutes/PermisosProtected";
import ResolverAuth from "./Components/Auth/ResolverAuth";
import Header from "./Components/Header/Header";
import Login from "./Components/Login/Login";
import theme from "./UI/Theme/Theme";
import Home from "./Components/Home/Home";
import ElegirClub from "./Components/ElegirClub/ElegirClub";
import Turnos from "./Components/Turnos/Turnos";
import CrearTurno from "./Components/Turnos/CrearTurno";
import Menu from "./Components/Turnos/Menu";
import Reservas from "./Components/Turnos/Reservas";
import Pasados from "./Components/Turnos/Pasados";
import ListaPersonal from "./Components/Personal/ListaPersonal.jsx";
import CrearUsuario from "./Components/Personal/CrearUsuario.jsx";
import EditarUsuario from "./Components/Personal/EditarUsuario.jsx";
import MenuClub from "./Components/Club/MenuClub.jsx";
import Chats from "./Components/Chats/Chats";
import Checkout from "./Components/Checkout/Checkout";
import Facturacion from "./Components/Facturacion/Facturacion";
import EditarPerfil from "./Components/Perfil/EditarPerfil";
import TurnoDetalle from "./Components/Turnos/TurnoDetalle";
import CrearTurnoUI from "./Components/Turnos/CrearTurnoUI";
///////////////////////////
// import ClubRoutes from "./Components/ProtectedRoutes/ClubRoutes";
// import ReclamosRoutes from "./Components/ProtectedRoutes/ReclamosRoutes";
// import AccountRegister from "./Components/AccountRegister/AccountRegister";
// import ClubRegister from "./Components/ClubRegister/ClubRegister";
// import Torneos from "./Components/Torneos/Torneos.jsx";
// import SinClub from "./Components/SinClub/SinClub";
// import Reclamo from "./Components/Reclamos/Reclamo";
// import CrearTorneo from "./Components/Torneos/Crear/CrearTorneo";
// import TorneoBracket from "./Components/Torneos/TorneoBracket.jsx";
// import Inscriptos from "./Components/Torneos/Inscriptos.jsx";

function App() {
  const permisos = useSelector((state) => state.permisos);
  const cliente = useSelector((state) => state.cliente.data);

  useInterceptors(servidor);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<AuthRoutes />}>
            <Route path="" element={<Login />} />
          </Route>
          {/* <Route path="/registrarse" element={<AuthRoutes />}>
            <Route path="" element={<AccountRegister />} />
          </Route> */}
          {/* <Route path="/clubes" element={<ClubRoutes />}>
            <Route path="" element={<ElegirClub />} />
            <Route path="registrar" element={<ClubRegister />} />
            <Route path="registrar/:id" element={<ClubRegister />} />
          </Route> */}
          {/* <Route path="/reclamos" element={<ReclamosRoutes />}>
            <Route path="" element={<Reclamo />} />
          </Route> */}
          <Route path="/activationSuccess" element={<Checkout />} />
          <Route path="/" element={<Header />}>
            <Route path="/chats/:chatId" element={<Chats />} />

            <Route path="/" element={<Home />} />
            {/* <Route
              path="/torneos"
              element={
                <PermisosProtected permitido={permisos.includes("torneos")} />
              }
            >
              <Route path="" element={<Torneos />} />
              <Route path="inscriptos/:id" element={<Inscriptos />} />
              <Route path="nuevotorneo" element={<CrearTorneo />} />
              <Route path="torneobracket" element={<TorneoBracket />} />
            </Route> */}
            <Route path="/facturacion" element={<Facturacion />}>
              {/**more pages inside*/}
            </Route>
            <Route
              path="/turnos"
              element={
                <PermisosProtected permitido={permisos.includes("reservas")} />
              }
            >
              <Route path="" element={<Menu />} />

              <Route path="actuales" element={<Turnos />} />

              <Route path="/turnos/:turnoId" element={<TurnoDetalle />} />

              <Route
                path="/turnos/crearturno"
                element={
                  <PermisosProtected
                    permitido={cliente?.metodoPagoActivado}
                    action
                  />
                }
              >
                <Route path="" element={<CrearTurnoUI />} />
              </Route>
              <Route path="reservas" element={<Reservas />} />
              <Route path="pasados" element={<Pasados />} />
            </Route>
            <Route
              path="/personal"
              element={
                <PermisosProtected permitido={permisos.includes("staff")} />
              }
            >
              <Route path="" element={<ListaPersonal />} />
              <Route path="nuevo" element={<CrearUsuario />} />
              <Route path="editar/:id" element={<EditarUsuario />} />
            </Route>
            <Route path="/perfil" element={<EditarPerfil />}></Route>
            <Route
              path="/mi-club"
              element={
                <PermisosProtected
                  permitido={permisos.includes("configuracion")}
                />
              }
            >
              <Route path="" element={<MenuClub />} />
            </Route>
            {/* <Route path="/sinclub" element={<SinClub />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
