const INITIAL_STATE = {
  data: [],
  miClub: null,
  cargando: false,
  error: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CLUBES_DATA":
      return { ...state, data: action.payload };
    case "CLUBES_MI_CLUB":
      return { ...state, miClub: action.payload };
    case "CLUBES_CARGANDO":
      return { ...state, cargando: action.payload };
    case "CLUBES_ERROR":
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
