import { Box, List, ListItem, Paper } from "@mui/material";
import React from "react";

const LayoutTabs = ({ children }) => {
  return (
    <Box sx={root}>
      <Paper sx={paperStyle} elevation={3}>
        <List>
          <ListItem
            // disablePadding
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {children}
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
};
const paperStyle = {
  width: "100%",
  mt: "1em",
};
const root = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
  mt: "1em",
  mb: "2em",
};
export default LayoutTabs;
