import servidor from "../../api/servidor";

export const miCuenta = () => async (dispatch) => {
  dispatch({ type: "CARGANDO_AUTH", payload: true });
  dispatch({ type: "USUARIO_ERROR", payload: false });

  try {
    const respuesta = await servidor.get("/v2/users/me");

    const { club } = respuesta.data.data;

    if (club) {
      dispatch({
        type: "PERMISOS_DATA",
        payload: club.permisos,
      });

      if (club.club) {
        dispatch({
          type: "CLUBES_MI_CLUB",
          payload: club.club,
        });
        dispatch({
          type: "CLIENTE_DATA",
          payload: club.club.cliente,
        });
      }
    }

    const usuario = {
      ...respuesta.data.data,
      club: undefined,
      tenis: undefined,
      padel: undefined,
    };

    dispatch({ type: "USUARIO_DATA", payload: usuario });

    dispatch({ type: "SESION_INICIADA" });
  } catch (err) {
    dispatch({ type: "USUARIO_ERROR", payload: true });
  }
  dispatch({ type: "CARGANDO_AUTH", payload: false });
};

export const actualizarCuenta = (data) => async (dispatch) => {
  dispatch({ type: "USUARIO_ERROR", payload: false });
  dispatch({ type: "USUARIO_CARGANDO", payload: true });

  try {
    const respuesta = await servidor.patch("/v2/users/me", data);

    dispatch({ type: "USUARIO_DATA", payload: respuesta.data.data });
  } catch (err) {
    console.log(err.response.data);
    dispatch({ type: "USUARIO_ERROR", payload: true });
  }

  dispatch({ type: "USUARIO_CARGANDO", payload: false });
};
