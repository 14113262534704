const INITIAL_STATE = {
  listaHorarios:[{
    horaInicio:'09:00',
    horaFinal:'10:00',
    precio:2000
  },
  {
    horaInicio:'10:00',
    horaFinal:'11:00',
    precio:2100
  },
  {
    horaInicio:'11:00',
    horaFinal:'12:00',
    precio:2200
  },
  {
    horaInicio:'12:00',
    horaFinal:'13:00',
    precio:2300
  },
  {
    horaInicio:'13:00',
    horaFinal:'14:00',
    precio:2400
  }],
  cargando: false,
  error: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_DATA":
      return { ...state, listaHorarios: action.payload };
    case "LOADING_DATA":
      return { ...state, cargando: action.payload };
    case "DATA_ERROR":
      return { ...state, error: action.payload };
      case "RESET_DATA":
        return INITIAL_STATE
    default:
      return state;
  }
};
