import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthRoutes = () => {
  const estadoAutorizacion = useSelector(
    (state) => state.auth.estadoAutorizacion
  );

  if (estadoAutorizacion === "pendiente") {
    return <Navigate to="/" replace />;
  }

  if (estadoAutorizacion === "aprobado") {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default AuthRoutes;
