import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  Box,
  Fade,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { differenceInMinutes, format } from "date-fns";
import {
  addHours,
  addMinutes,
  getHours,
  addDays,
  getDayOfYear,
} from "date-fns/esm";
import deLocale from "date-fns/locale/es";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setTime } from "../../utils/helperFunctions";

const AddTurno = ({
  addMore,
  setAddMore,
  lastTime,
  cancha,
  array,
  setArray,
  diaSelected,
  turnosRedux,
}) => {
  //redux
  const club = useSelector((state) => state.clubes.miClub);
  //states
  const [start, setStart] = useState(setTime(lastTime, diaSelected)); //convierte horaString a Fecha
  const [end, setEnd] = useState(
    cancha
      ? cancha.deporte
        ? cancha.deporte === "tenis"
          ? addHours(start, 1)
          : addMinutes(addHours(start, 1), 30)
        : addHours(start, 1)
      : addHours(start, 1)
  );
  const [price, setPrice] = useState();
  const [lightPrice, setLightPrice] = useState();
  const [existingTurn, setExistingturn] = useState(false);

  const checkExistingTurn = () => {
    const findArray = array.find(
      (item) =>
        format(new Date(item.fechaInicio), "k:mm") === format(start, "k:mm") &&
        format(new Date(item.fechaFinal), "k:mm") === format(end, "k:mm")
    );
    const find = turnosRedux.find(
      (item) =>
        format(new Date(item.fechaInicio), "k:mm") === format(start, "k:mm") &&
        format(new Date(item.fechaFinal), "k:mm") === format(end, "k:mm")
    );
    if (find || findArray) {
      return setExistingturn(true);
    }
    return setExistingturn(false);
  };

  useEffect(() => {
    let precio;
    let precioLuz;
    const hours = differenceInMinutes(end, start) / 60;
    if (cancha.deporte === "tenis") {
      precio = (hours * club.precioHoraTenis).toFixed(0);
      precioLuz = (hours * club.precioHoraLuzTenis).toFixed(0);
    } else {
      precio = (hours * club.precioHoraPadel).toFixed(0);
      precioLuz = (hours * club.precioHoraLuzPadel).toFixed(0);
    }
    setPrice(precio);
    setLightPrice(precioLuz);
    checkExistingTurn();
  }, [end, start]);

  const handleSave = () => {
    setArray([
      ...array,
      {
        fechaInicio: start,
        fechaFinal: end,
        cancha: cancha._id,
        precio: price,
        deporte: cancha.deporte,
      },
    ]);
    setAddMore(false);
  };
  return (
    <Fade in={addMore} unmountOnExit>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={root}>
          <Box sx={left}>
            <Box sx={pickersContainer}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={deLocale}
              >
                <MobileTimePicker
                  name="horaInicio"
                  required
                  label="Inicio"
                  value={start}
                  minTime={new Date("", "", "", 6, 0)}
                  // maxTime={new Date("", "", "", 22, 0)}
                  minutesStep={30}
                  onChange={(e) => {
                    setStart(e);
                    if (cancha.deporte === "tenis") {
                      setEnd(addHours(e, 1));
                    } else {
                      setEnd(addMinutes(addHours(e, 1), 30));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={deLocale}
              >
                <MobileTimePicker
                  name="horaFinal"
                  required
                  label="Final"
                  value={end}
                  minTime={addHours(start, 1)}
                  maxTime={addHours(start, 2)}
                  minutesStep={30}
                  disableIgnoringDatePartForTimeValidation
                  onChange={(e) => {
                    setEnd(e);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <TextField
              sx={{ mt: "1em" }}
              id="outlined-select-currency"
              label={"Precio"}
              size={"small"}
              value={price}
              onChange={(e) => {
                if (!e.target.value) {
                  setPrice();
                } else {
                  setPrice(parseInt(e.target.value.replace(/[^0-9]/g, "")));
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={right}>
            <IconButton
              sx={{ color: "#32c06d" }}
              disabled={!price || price < 1 || existingTurn}
              onClick={handleSave}
            >
              <TaskAltIcon sx={{ fontSize: "2em" }} />
            </IconButton>
          </Box>
        </Box>
        {existingTurn ? (
          <Box sx={{ textAlign: "center", mt: "1em" }}>
            <Typography
              variant="title"
              color="common.negroClaro2"
              sx={{ color: "red" }}
            >
              El turno en el horario elegido ya existe, por favor seleccione
              otro horario
            </Typography>
          </Box>
        ) : (
          <Box sx={{ textAlign: "center", mt: "1em" }}>
            <Typography
              variant="title"
              color="common.negroClaro2"
              sx={{ color: "#ff6934" }}
            >
              {` El precio de la luz ($${lightPrice}) no se debe incluir en el
                  precio del turno. Se debe cobrar aparte en el club.`}
            </Typography>
          </Box>
        )}
      </Box>
    </Fade>
  );
};
const root = {
  display: "flex",
  flexDirection: "row",
};
const left = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  mt: 5,
};
const right = {
  margin: "auto",
};
const pickersContainer = {
  display: "flex",
  flexDirection: "row",
  mt: 5,
};
export default AddTurno;
