import SendIcon from "@mui/icons-material/Send";
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
const SendInput = ({ setSendInput, inputValue, onClick, onKeyDown }) => {
  return (
    <Box sx={container}>
      <input
        value={inputValue}
        onChange={(e) => {
          setSendInput(e.target.value);
        }}
        style={input}
        type="text"
        placeholder="Escribe un mensaje aquí"
        onKeyDown={onKeyDown}
      />
      <Tooltip title={"Enviar mensaje"}>
        <IconButton onClick={onClick}>
          <SendIcon style={{ margin: 10, color: "#f08535" }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
const container = {
  width: "97%",
  display: "flex",
  height: "7vh",
  margin: 5,
  borderRadius: 5,
  backgroundColor: "#fff",
  alignSelf: "center",
};
const input = {
  flex: 1,
  outline: "none",
  border: "none",
  marginLeft: 20,
  zIndex: 1,
  opacity: 0.8,
};
export default SendInput;
