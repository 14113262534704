import React, { useEffect } from "react";
import { Box, Alert, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Error = ({ texto, open, setOpen, onClose }) => {
  useEffect(() => {
    if (open) {
      setTimeout(() => setOpen(false), 5000);
    }
  }, [open]);

  return (
    <Box sx={{ position: "absolute", top: "1em", right: "1em" }}>
      <Collapse
        in={open}
        addEndListener={() => {
          setTimeout(() => {
            onClose();
            setOpen(false);
          }, 3000);
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                onClose();
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {texto}
        </Alert>
      </Collapse>
    </Box>
  );
};

export default Error;
