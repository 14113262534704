import { createTheme } from "@mui/material/styles";

const primario = "#FF6934";
const primarioClaro = "#FFA585";
const secundario = "#5BC7C8";
const secundarioClaro = "#9DDDDE";
const negroClaro2 = "#424242";
const grisClaro4 = "#F8F8F8";

const theme = createTheme({
  palette: {
    common: {
      blanco: "#FFFFFF",
      gris: "#DDDDDD",
      grisClaro1: "#E4E4E4",
      grisClaro2: "#EBEBEB",
      grisClaro3: "#F1F1F1",
      grisClaro4: "#F8F8F8",
      principal: "#FF6934",
      principalClaro1: "#FF875D",
      principalClaro2: "#FFA585",
      principalClaro3: "#FFC3AE",
      principalClaro4: "#FFE1D6",
      secundario: "#5BC7C8",
      secundarioClaro1: "#7CD2D3",
      secundarioClaro2: "#9DDDDE",
      secundarioClaro3: "#BDE9E9",
      secundarioClaro4: "#DEF4F4",
      negro: "#000000",
      negroClaro1: "#121212",
      negroClaro2: "#424242",
      negroClaro3: "#757575",
      negroClaro4: "#999999",
      tercero: "#D6FF59",
      terceroClaro1: "#E7FF80",
      terceroClaro2: "#F1FFA1",
      terceroClaro3: "#F6FFC0",
      terceroClaro4: "#F9FFD9",
    },
    primary: {
      main: primario,
      light: primarioClaro,
      contrastText: "#F8F8F8",
    },
    secondary: {
      main: secundario,
      light: secundarioClaro,
      contrastText: "#F8F8F8",
    },
    background: {
      default: "#F8F8F8",
    },
    text: {
      primary: "#424242",
      secondary: "#999999",
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: "Arimo",
    h1: {
      fontSize: "4rem",
      color: primario,
      fontWeight: "600",
    },
    h2: {
      fontSize: "3.4rem",
      color: negroClaro2,
      fontWeight: "600",
    },
    h3: {
      fontSize: "2.8rem",
      color: primario,
      fontWeight: "600",
    },
    h4: {
      fontSize: "2.2rem",
      color: negroClaro2,
      fontWeight: "600",
    },
    h5: {
      fontSize: "2rem",
      color: primario,
      fontWeight: "600",
    },
    h6: {
      fontSize: "1.8rem",
      color: negroClaro2,
      fontWeight: "600",
    },
    subtitle1: {
      fontSize: "1.7rem",
      color: primario,
      fontWeight: "500",
    },
    subtitle2: {
      fontSize: "1.7rem",
      color: grisClaro4,
      fontWeight: "500",
    },
    body1: {
      fontSize: "1.7rem",
      color: negroClaro2,
      fontWeight: "400",
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "1.7rem",
      color: grisClaro4,
      fontWeight: "400",
      lineHeight: 1.5,
    },
    title:{
      fontSize: '1.5rem',
      color: negroClaro2
    },
    title2:{
      fontSize: '1.4rem',
      color: negroClaro2
    },
    subTitle:{
      fontSize: '1.2rem',
      color: negroClaro2
    },
    button: {
      fontSize: "1.2rem",
    },
    caption: {
      fontSize: '1.2rem',
    },
    overline: {
      fontSize: "1rem",
    },
    success:'#cdffcd'
    
  },
  spacing: 2,
  components: {
    MuiTableCell: {
      head: {
        color: "#F8F8F8",
        fontFamily: "Arimo",
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "large",
        },
      },
    },
  },
});

export default theme;
