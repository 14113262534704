import { Avatar, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Types } from "../../reducers/chatsReducer";
import DropdownList from "./DropdownList";

const etiquetas = ["Todos", "General", "Reservas", "Torneos"];
const HeaderLeft = ({ photoURL }) => {
  const dispatch = useDispatch();
  const [select, setSelect] = useState("");
  useEffect(() => {
    if (select === "Todos") {
      dispatch({ type: Types.FILTER_CHAT, payload: "" });
    } else {
      dispatch({ type: Types.FILTER_CHAT, payload: select.toLowerCase() });
    }
  }, [select]);

  return (
    <Box sx={container}>
      <Avatar src={photoURL} style={{ marginLeft: 15 }} />
      {/* <DropdownList
        array={etiquetas}
        defaultValue={etiquetas[0]}
        setValue={(e) => setSelect(e)}
      /> */}
    </Box>
  );
};
const container = {
  display: "flex",
  flexDirection: "row",
  backgroundColor: "#f0f2f5", //f9ede1 f0f2f5
  height: 40,
  alignItems: "center",
  justifyContent: "space-between",
  padding: 5,
};

export default HeaderLeft;
