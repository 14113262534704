import { useDispatch } from "react-redux";

export default (instancia) => {
  const dispatch = useDispatch();

  instancia.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response) {
        if (
          error.response.status === 401 &&
          error.response.data.message === "Usuario bloqueado"
        ) {
          dispatch({ type: "USUARIO_BLOQUEADO" });
        } else if (
          error.response.status === 401 &&
          error.response.data.message !== "Usuario bloqueado"
        ) {
          dispatch({ type: "SESION_CERRADA" });
        } else {
        }
      }
      return Promise.reject(error);
    }
  );
};
