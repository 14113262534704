export const errorDispatch = (err, dispatch, type) => {
  if (err.response) {
    if (err.response.data) {
      dispatch({ type, payload: err.response.data.message });
    } else {
      dispatch({
        type,
        payload: "Ups... ocurrió un error, intentar de nuevo",
      });
    }
  } else {
    dispatch({
      type,
      payload: "Ups... ocurrió un error, intentar de nuevo",
    });
  }
};
