export const errorLocal = (err, type) => (dispatch) => {
  if (err.response) {
    if (err.response.data) {
      console.log(err.response.data.message);
      dispatch({ type, payload: err.response.data.message });
    } else {
      console.log(err.response);
      dispatch({
        type,
        payload: "Ups... ocurrió un error, intentar de nuevo",
      });
    }
  } else {
    console.log(err);
    dispatch({
      type,
      payload: "Ups... ocurrió un error, intentar de nuevo",
    });
  }
};

export const errorGlobal = (err) => (dispatch) => {
  if (err.response) {
    if (err.response.data) {
      dispatch({ type: "agregar_error", payload: err.response.data.message });
    } else {
      dispatch({
        type: "agregar_error",
        payload: "Ocurrió un error, intentar de nuevo",
      });
    }
  } else {
    dispatch({
      type: "agregar_error",
      payload: "Ocurrió un error, intentar de nuevo",
    });
  }
};
