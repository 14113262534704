import React from "react";
import { Grid, Typography } from "@mui/material";

const EmptyMessage = ({ texto }) => {
  return (
    <Grid container sx={{ mt: "4em" }} justifyContent="center">
      <Typography variant="h4" color="common.negroClaro2">
        {texto}
      </Typography>
    </Grid>
  );
};

export default EmptyMessage;
