import servidor from "../../api/servidor";

export const buscarClubes = (nombre = "") => async (dispatch) => {
  dispatch({ type: "CLUBES_CARGANDO", payload: true });
  dispatch({ type: "CLUBES_ERROR", payload: false });

  try {
    let respuesta;

    if (nombre) {
      respuesta = await servidor.get(
        `/clubes/?nombre=${nombre}&limit=20&page=1`
      );
    } else {
      respuesta = await servidor.get(`/clubes/?limit=20&page=1`);
    }

    dispatch({ type: "CLUBES_DATA", payload: respuesta.data.data });
  } catch (err) {
    dispatch({ type: "CLUBES_ERROR", payload: true });
  }

  dispatch({ type: "CLUBES_CARGANDO", payload: false });
};

export const actualizarClub = (data) => async (dispatch) => {
  dispatch({ type: "CLUBES_CARGANDO", payload: true });
  dispatch({ type: "CLUBES_ERROR", payload: false });

  try {
    const respuesta = await servidor.patch("/v2/clubs/myClub", data);

    dispatch({ type: "CLUBES_MI_CLUB", payload: respuesta.data.data });
  } catch (err) {
    dispatch({ type: "CLUBES_ERROR", payload: true });
  }

  dispatch({ type: "CLUBES_CARGANDO", payload: false });
};
