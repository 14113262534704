export const Types = {
  GET_CHAT: "chats_get",
  ADD_CHAT: "chats_get_agregar",
  SEARCH_CHAT: "chats_busqueda",
  CLEAN_CHAT: "chat_limpiar",
  ERROR_CHAT: "chat_error",
  LOADING_CHAT: "chat_cargando",
  FILTER_CHAT: "chat_etiqueta",
};

const INITIAL_STATE = {
  chats: [],
  chat: { id: "", miembros: [] },
  cargando: false,
  cargandoMas: false,
  error: false,
  busqueda: "",
  etiqueta: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_CHAT:
      return { ...state, chats: action.payload };
    case Types.ADD_CHAT:
      return { ...state, chats: [state.chats, ...action.payload] };
    case Types.SEARCH_CHAT:
      return { ...state,busqueda: action.payload };
    case Types.CLEAN_CHAT:
      return INITIAL_STATE;
    case Types.ERROR_CHAT:
      return { ...state, error: action.payload };
    case Types.LOADING_CHAT:
      return { ...state, cargando: action.payload };
    case Types.FILTER_CHAT:
      return { ...state, etiqueta: action.payload };
    default:
      return state;
  }
};
