import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ChatIcon from "@mui/icons-material/Chat";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import PeopleIcon from "@mui/icons-material/People";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import BadgeIcon from "@mui/icons-material/Badge";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSelector } from "react-redux";
import { bucketURL } from "../../api/urls";

const drawerWidth = 200;

function SideBar(props) {
  const { window } = props;
  const navigate = useNavigate();
  const club = useSelector((state) => state.clubes.miClub);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const primeraTanda = [
    {
      titulo: "Turnos",
      componente: (
        <ListItemIcon>
          <EventNoteIcon color="primary" />
        </ListItemIcon>
      ),
      disabled: false,
    },
    {
      titulo: "Chats",
      componente: (
        <ListItemIcon>
          <ChatIcon color="primary" />
        </ListItemIcon>
      ),
      disabled: false,
    },
    {
      titulo: "Torneos",
      componente: (
        <ListItemIcon>
          <EmojiEventsIcon color="primary" />
        </ListItemIcon>
      ),
      disabled: true,
    },
  ];

  const segundaTanda = [
    {
      titulo: "Profesores",
      componente: (
        <ListItemIcon>
          <SportsTennisIcon color="primary" />
        </ListItemIcon>
      ),
      disabled: true,
    },
    {
      titulo: "Clientes",
      componente: (
        <ListItemIcon>
          <PeopleIcon color="primary" />
        </ListItemIcon>
      ),
      disabled: true,
    },
    {
      titulo: "Personal",
      componente: (
        <ListItemIcon>
          <BadgeIcon color="primary" />
        </ListItemIcon>
      ),
      disabled: false,
    },
  ];

  const terceraTanda = [
    {
      titulo: "Facturacion",
      componente: (
        <ListItemIcon>
          <PointOfSaleIcon color="primary" />
        </ListItemIcon>
      ),
      disabled: false,
    },
    {
      titulo: "Métricas",
      componente: (
        <ListItemIcon>
          <EqualizerIcon color="primary" />
        </ListItemIcon>
      ),
      disabled: true,
    },
  ];

  const cuartaTanda = [
    {
      titulo: "Mi Club",
      componente: (
        <ListItemIcon>
          <SettingsIcon color="primary" />
        </ListItemIcon>
      ),
      disabled: false,
    },
    {
      titulo: "Perfil",
      componente: (
        <ListItemIcon>
          <AccountCircleIcon color="primary" />
        </ListItemIcon>
      ),
      disabled: false,
    },
  ];

  const drawer = (
    <div>
      <Toolbar>
        <Grid container justifyContent="center">
          <Avatar
            src={`${bucketURL}/${club.logo}`}
            alt="logo"
            sx={{ width: "4.5em", height: "4.5em" }}
          />
        </Grid>
      </Toolbar>
      <Divider />
      <List disablePadding>
        {primeraTanda.map((item, index) => (
          <ListItem button key={index} disabled={item.disabled}>
            {item.componente}
            <ListItemText
              primary={item.titulo}
              onClick={() => {
                if (item.titulo === "Chats") {
                  navigate(`/${item.titulo.toLowerCase()}/1`);
                  setMobileOpen(false);
                } else {
                  navigate(`/${item.titulo.toLowerCase()}`);
                  setMobileOpen(false);
                }
              }}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List disablePadding>
        {segundaTanda.map((item, index) => (
          <ListItem button key={index} disabled={item.disabled}>
            {item.componente}
            <ListItemText
              primary={item.titulo}
              onClick={() => {
                navigate(`/${item.titulo.toLowerCase()}`);
                setMobileOpen(false);
              }}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List disablePadding>
        {terceraTanda.map((item, index) => (
          <ListItem button key={index} disabled={item.disabled}>
            {item.componente}
            <ListItemText
              primary={item.titulo}
              onClick={() => {
                navigate(`/${item.titulo.toLowerCase()}`);
                setMobileOpen(false);
              }}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List disablePadding>
        {cuartaTanda.map((item, index) => (
          <ListItem button key={index} disabled={item.disabled}>
            {item.componente}
            <ListItemText
              primary={item.titulo}
              onClick={() => {
                if (item.titulo === "Mi Club") {
                  navigate("/mi-club");
                } else {
                  navigate(`/${item.titulo.toLowerCase()}`);
                }

                setMobileOpen(false);
              }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Toolbar />
    </Box>
  );
}

export default SideBar;
