import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Paper } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actualizarCuenta } from "../../actions/usuario";
import TituloPantalla from "../Titulos/TituloPantalla";
import { bucketURL } from "../../api/urls";

const EditarPerfil = () => {
  //redux
  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.usuario.data);
  const club = useSelector((state) => state.clubes.miClub);
  const cargando = useSelector((state) => state.usuario.cargando);
  //states
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setName(usuario.nombre);
    setLastname(usuario.apellido);
    setEmail(usuario.email);
  }, [usuario]);

  const handleUpdate = () => {
    if (cargando) return;

    dispatch(actualizarCuenta({ nombre: name, apellido: lastname, email }));
  };

  return (
    <Box className="main">
      <TituloPantalla titulo={"Perfil"} />
      <Paper sx={{ maxWidth: "xs" }}>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ m: 1, width: "5em", height: "5em" }}
              src={`${bucketURL}/${club.logo}`}
            />

            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <FormItem
                  title={"Nombre"}
                  defaultValue={name}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <FormItem
                  title={"Apellido"}
                  defaultValue={lastname}
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
                <FormItem
                  title={"Correo"}
                  defaultValue={email}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {/* <FormItem
                  title={"Contraseña"}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                /> */}
              </Grid>
              <Box
                sx={{
                  mt: 7,
                  ml: 57,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <a
                  href={`https://wa.me/+5493516019304?text=Hola%20Flobi!%20Quiero%20cambiar%20mi%20contraseña...%20mi%20usuario%20es:%20${usuario.email}`}
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="subTitle" color={"primary"}>
                    Cambiar Contraseña
                  </Typography>
                </a>

                <a
                  href={`https://wa.me/+5493516019304?text=Hola%20Flobi!%20Quiero%20eliminar%20mi%20cuenta...%20mi%20usuario%20es:%20${usuario.email}`}
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="subTitle" color={"grisClaro4"}>
                    Eliminar Cuenta
                  </Typography>
                </a>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 20 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleUpdate}
                  loading={cargando}
                >
                  Confirmar datos
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Container>
      </Paper>
    </Box>
  );
};

const FormItem = ({ title, value, type = "text", defaultValue, onChange }) => {
  return (
    <Grid sx={formContainer} item xs={12}>
      <Grid item xs={3}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item xs={9}>
        <TextField
          name="firstName"
          fullWidth
          autoFocus
          size="small"
          type={type}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};
const formContainer = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
export default EditarPerfil;
