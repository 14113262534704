import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import React from "react";

const Notice = ({ title, onClick, loading, titleButton, hasButton }) => {
  return (
    <>
      <Typography variant="h5" sx={titleStyle}>
        {title}
      </Typography>
      {hasButton && (
        <Box sx={footerStyle}>
          <LoadingButton
            variant="contained"
            onClick={onClick}
            loading={loading}
          >
            {titleButton}
          </LoadingButton>
        </Box>
      )}
    </>
  );
};
const titleStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  mt: 30,
  marginX: 20,
};
const footerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  mt: 15,
  marginX: 20,
};
export default Notice;
