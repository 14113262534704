import EventIcon from "@mui/icons-material/Event";
import TuneIcon from "@mui/icons-material/Tune";
import { Box, Button, Chip, CircularProgress, Grid } from "@mui/material";
import { LocalizationProvider, MonthPicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import deLocale from "date-fns/locale/es";
import { getMonth } from "date-fns";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { delay, getMonthName } from "../../utils/helperFunctions";
import CustomModal from "../Modals/CustomModal";
import TituloPantalla from "../Titulos/TituloPantalla";
import FilterModal from "./FilterModal";
import TableFacturation from "./TableFacturation";
import ErrorGet from "../Turnos/ErrorGet";
import Loader from "../Loaders/Loader";
import EmptyMessage from "../Empty/EmptyMessage";
import { getFacturacion, getTotal } from "../../actions/facturacion";
const headColumns = [
  "Fecha",
  "Inicio",
  "Cancha",
  "Precio",
  "Comision %",
  "Señado",
  "Monto a Pagar",
];

const Facturacion = () => {
  //state
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [month, setMonth] = useState(new Date());
  const [modalMonth, setModalMonth] = useState(month);
  const [fetch, setFetch] = useState(true);

  const [paidOut, setPaidOut] = useState(true); //USE REDUX DATA
  //redux
  const dispatch = useDispatch();
  const facturacion = useSelector((state) => state.facturacion.data);
  const pagado = useSelector((state) => state.facturacion.pagado);
  const cargando = useSelector((state) => state.facturacion.cargando);
  const error = useSelector((state) => state.facturacion.error);

  //effect
  useEffect(() => {
    if (fetch && !cargando) {
      fetchFacturacion();
      fetchTotal();
      setFetch(false);
      setShowModal2(false);
      setShowModal(false);
    }
  }, [fetch, cargando]);

  const handleApply = () => {
    setMonth(modalMonth);
    fetchFacturacion();
    fetchTotal();
  };

  const fetchFacturacion = () => {
    const monthNumber = getMonth(month);
    dispatch(getFacturacion(monthNumber));
    setFetch(true);
  };

  const fetchTotal = () => {
    const monthNumber = getMonth(month);
    dispatch(getTotal(monthNumber));
  };

  //====Modal Content====
  const BodyModal = () => {
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
          <MonthPicker date={modalMonth} onChange={(e) => setModalMonth(e)} />
        </LocalizationProvider>
        {cargando ? (
          <Box sx={loaderStyle}>
            <CircularProgress />
          </Box>
        ) : null}
      </>
    );
  };

  return (
    <Box className="main">
      <TituloPantalla titulo="Facturación" />
      <Grid
        container
        item
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Grid item>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              sx={{ width: 130 }}
              onClick={() => setShowModal2(true)}
            >
              {getMonthName(month.getMonth())}
              <EventIcon sx={{ marginLeft: 5 }} />
            </Button>

            <Button
              variant="contained"
              sx={filterStyle}
              onClick={() => setShowModal(true)}
            >
              Filtros <TuneIcon sx={{ marginLeft: 5 }} />
            </Button>
          </Box>
        </Grid>
        <Grid item maxWidth={130} mr={10} sx={{ mt: { xs: "2em" } }}>
          <Chip
            label={pagado ? "Mes pagado ✓" : "Mes sin pagar X"}
            size="medium"
            sx={{
              fontSize: "1.5em",
              color: pagado ? "#2d992c" : "#ce8401",
              backgroundColor: pagado ? "#cdffcd" : "#ffeccc",
            }}
          />
        </Grid>
      </Grid>
      <Grid item mt={5}>
        {error ? (
          <ErrorGet titulo={error} accion={fetchFacturacion} />
        ) : cargando ? (
          <Loader />
        ) : facturacion.length < 1 ? (
          <EmptyMessage texto="No hay ventas este mes..." />
        ) : (
          <TableFacturation tableHead={headColumns} data={facturacion} />
        )}
      </Grid>
      <FilterModal showModal={showModal} setShowModal={setShowModal} />
      <CustomModal
        showModal={showModal2}
        setShowModal={setShowModal2}
        title={"Mes de facturación"}
        apply={handleApply}
        body={<BodyModal />}
      />
    </Box>
  );
};
const filterStyle = {
  backgroundColor: "#f0f2f5",
  color: "#000",
  "&:hover": { backgroundColor: "#ffeccc" },
  marginLeft: "1em",
};
const loaderStyle = {
  display: "flex",
  justifyContent: "center",
  mt: 5,
};
export default Facturacion;
