import React, { useState, useEffect } from "react";
import { Box, Alert, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";

const GlobalErrorHandler = () => {
  const dispatch = useDispatch();

  const error = useSelector((state) => state.error);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (error) {
      handleOpen();
    }
  }, [error]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch({ type: "eliminar_error" });
  };

  return (
    <Box sx={{ position: "absolute", top: "1em", right: "5em" }}>
      <Collapse
        in={open}
        addEndListener={() => {
          setTimeout(() => {
            handleClose();
          }, 3000);
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          sx={{ fontSize: "2rem" }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      </Collapse>
    </Box>
  );
};

export default GlobalErrorHandler;
