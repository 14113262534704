import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { primeraLetraMayuscula } from "../../Handle/Handle";
import { delay, getDayName } from "../../utils/helperFunctions";
import LayoutTabs from "./LayoutTabs";
import ChangeTimeModal from "./ChangeTimeModal";
import CustomModal from "../Modals/CustomModal";
import TabRow from "./TabRow";
import { getZero } from "../../utils/helperFunctions";
import { actualizarClub } from "../../actions/clubes";

const Predio = () => {
  //states
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [equipment, setEquipment] = useState("");
  const [checked, setChecked] = useState(false);
  const [fetched, setFetched] = useState(false);
  //redux
  //dispatch
  const dispatch = useDispatch();
  //selectors
  const club = useSelector((state) => state.clubes.miClub);
  const cargando = useSelector((state) => state.clubes.cargando);
  //effect
  useEffect(() => {
    if (fetched && !cargando) {
      setShowModal(false);
      setShowModal2(false);
      setFetched(false);
    }

    setChecked(club[equipment.toLowerCase()]);
  }, [fetched, cargando, equipment]);

  const handleUpdate = async () => {
    const equipmentLowerCase = equipment.toLowerCase();
    const data = { [equipmentLowerCase]: checked };
    dispatch(actualizarClub(data));
    setFetched(true);
  };

  const handleCheckedChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <LayoutTabs>
        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>Deportes</Typography>
          }
          centerComponent={
            <>
              {club?.deportes.length > 1 ? (
                <Typography>
                  {`${primeraLetraMayuscula(
                    club.deportes[0]
                  )} , ${primeraLetraMayuscula(club.deportes[1])} `}
                </Typography>
              ) : (
                <Typography>
                  {primeraLetraMayuscula(club.deportes[0])}
                </Typography>
              )}
            </>
          }
        />
        <div style={divider} />

        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>Horarios</Typography>
          }
          centerComponent={
            <Box flexDirection={"column"}>
              {club?.horariosArray.map((e) => (
                <Box flexDirection={"row"} display="flex">
                  <Box>
                    <Typography sx={{ minWidth: "5em" }}>
                      {getDayName(e.dia)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      {`${getZero(e.inicio.hora)}:${getZero(
                        e.inicio.minutos
                      )}hs  a ${getZero(e.final.hora)}:${getZero(
                        e.final.minutos
                      )}hs`}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          }
          rightComponent={
            <IconButton onClick={() => setShowModal2(true)}>
              <EditIcon />
            </IconButton>
          }
        />
        <div style={divider} />

        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>BAR</Typography>
          }
          centerComponent={
            club.bar ? (
              <CheckCircleIcon sx={tickStyle} />
            ) : (
              <CancelIcon sx={cancelStyle} />
            )
          }
          rightComponent={
            <IconButton
              onClick={() => {
                setEquipment("Bar");
                setShowModal(true);
              }}
            >
              <EditIcon />
            </IconButton>
          }
        />
        <div style={divider} />

        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>Vestuarios</Typography>
          }
          centerComponent={
            club.vestuarios ? (
              <CheckCircleIcon sx={tickStyle} />
            ) : (
              <CancelIcon sx={cancelStyle} />
            )
          }
          rightComponent={
            <IconButton
              onClick={() => {
                setEquipment("Vestuarios");
                setShowModal(true);
              }}
            >
              <EditIcon />
            </IconButton>
          }
        />
        <div style={divider} />
        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>
              Estacionamiento
            </Typography>
          }
          centerComponent={
            club.estacionamiento ? (
              <CheckCircleIcon sx={tickStyle} />
            ) : (
              <CancelIcon sx={cancelStyle} />
            )
          }
          rightComponent={
            <IconButton
              onClick={() => {
                setEquipment("Estacionamiento");
                setShowModal(true);
              }}
            >
              <EditIcon />
            </IconButton>
          }
        />
      </LayoutTabs>

      {/**======MODALS====== */}
      <CustomModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={"Cambiar estado de tus instalaciones"}
        disabled={cargando}
        apply={handleUpdate}
        body={
          <Box>
            <Box sx={modalContainer}>
              <Typography>{`Tiene ${equipment}?`}</Typography>
              <Checkbox
                size="medium"
                checked={checked}
                onChange={handleCheckedChange}
              />
            </Box>
            {cargando ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                <CircularProgress />
              </Box>
            ) : null}
          </Box>
        }
      />
      <ChangeTimeModal
        showModal={showModal2}
        setShowModal={setShowModal2}
        arrayData={club.horariosArray}
      />
    </>
  );
};
const divider = {
  width: "100%",
  height: 1,
  backgroundColor: "#DDDDDD",
  margin: 10,
};
const cancelStyle = {
  color: "#f70000",
  fontSize: "3em",
};
const tickStyle = {
  color: "#32c06d",
  fontSize: "3em",
};
const modalContainer = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
};
export default Predio;
