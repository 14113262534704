import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TituloPantalla from "../Titulos/TituloPantalla";
import ErrorGet from "../Turnos/ErrorGet";
import ConfirmarBorrar from "../Modals/ConfirmarBorrar";
import { getPersonal, borrarUsuario } from "../../actions/personal";
import { primeraLetraMayuscula } from "../../Handle/Handle";

const ListaPersonal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const personal = useSelector((state) => state.personal);
  console.log(useSelector((state) => state.usuario.data));
  const usuarioId = useSelector((state) => state.usuario.data._id);

  const [mostrarModal, setMostrarModal] = useState(false);
  const [mostrarError, setMostrarError] = useState(false);

  useEffect(() => {
    dispatch(getPersonal());
  }, []);

  const handleClickBorrar = (usuario) => {
    dispatch({ type: "PERSONAL_SELECTED", payload: usuario });
    setMostrarModal(true);
  };

  return (
    <>
      <ConfirmarBorrar
        open={mostrarModal}
        titulo="Borrar Usuario"
        texto="¿Estás seguro que queres borrar a este miembro?"
        handleBorrar={() => {
          dispatch(borrarUsuario(personal.selected._id));
          dispatch({ type: "PERSONAL_SELECTED", payload: null });
          setMostrarModal(false);
        }}
        handleCancelar={() => {
          dispatch({ type: "PERSONAL_SELECTED", payload: null });
          setMostrarModal(false);
        }}
      />
      <Box className="main">
        <TituloPantalla titulo="Personal" />
        <Grid container justifyContent="flex-end" sx={{ p: "0 2em 2em 0" }}>
          <Button
            onClick={() => navigate("/personal/nuevo")}
            variant="contained"
          >
            Crear nuevo usuario
          </Button>
        </Grid>
        <Grid container>
          {personal.error ? (
            <Grid item container justifyContent="center" sx={{ mt: "3em" }}>
              <ErrorGet
                titulo={personal.error}
                accion={() => {
                  dispatch(getPersonal());
                }}
              />
            </Grid>
          ) : personal.cargando ? (
            <Grid item container justifyContent="center" sx={{ mt: "3em" }}>
              <CircularProgress />
            </Grid>
          ) : (
            <Grid item xs={12} md={8}>
              <List>
                {personal.data.map((usuario, i) => {
                  const rol =
                    usuario.rol === "club-admin" ? "Administrador" : "Staff";
                  return (
                    <ListItem
                      key={i}
                      sx={{ width: "100%" }}
                      divider
                      secondaryAction={
                        rol === "Administrador" ||
                        usuario._id === usuarioId ? null : (
                          <Grid container>
                            <Grid item>
                              <IconButton
                                aria-label="llamar"
                                color="secondary"
                                size="large"
                                onClick={() => {
                                  dispatch({
                                    type: "PERSONAL_SELECTED",
                                    payload: usuario,
                                  });
                                  navigate(`/personal/editar/${usuario._id}`);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton
                                aria-label="llamar"
                                color="secondary"
                                size="large"
                                onClick={() => handleClickBorrar(usuario)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )
                      }
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar />
                        </ListItemAvatar>
                        <ListItemText
                          id={usuario._id}
                          primary={`${primeraLetraMayuscula(
                            usuario.nombre
                          )} ${primeraLetraMayuscula(usuario.apellido)}`}
                        />
                        <ListItemText id={usuario.rol} primary={rol} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ListaPersonal;
