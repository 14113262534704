import DoneIcon from "@mui/icons-material/Done";
import MicIcon from "@mui/icons-material/Mic";
import { Box, Typography } from "@mui/material";
import React from "react";

const ChatInfo = ({ name, message, date, time }) => {
  return (
    <Box sx={container}>
      <Box>
        <Typography variant="title" color="common.negro">
          {name}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {message.texto !== undefined &&
            message.audio !== undefined &&
            [1, 2].map((e) => {
              return <DoneIcon style={iconStyle} />;
            })}

          {message.tipoMensaje === "texto" && (
            <Typography
              variant="caption" //<--fontSize
              color="common.negroClaro2"
              sx={textMessage}
            >
              {message.texto}
            </Typography>
          )}

          {message.tipoMensaje === "audio" && (
            <>
              <MicIcon sx={micStyle} />
              <Typography
                variant="caption" //<--fontSize
                color="common.negroClaro2"
                sx={textMessage}
              >
                {message?.audio?.duracion}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <Box sx={right}>
        <Typography
          variant="caption" //<--fontSize
          color="common.negroClaro2"
        >
          {date}
        </Typography>
        <Typography
          variant="caption" //<--fontSize
          color="common.negroClaro2"
        >
          {time}
        </Typography>
      </Box>
    </Box>
  );
};
const container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
};
const textMessage = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: { xs: "18em", sm: 130, md: 130, lg: 160 },
  whiteSpace: "nowrap",
  marginLeft: 1,
};
const iconStyle = {
  color: "#424242",
  fontSize: 10,
  marginLeft: -6,
};
const micStyle = {
  color: "common.negroClaro2",
  marginRight: 1,
};
const right = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export default ChatInfo;
