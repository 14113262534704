const INITIAL_STATE = {
  _id: "",
  nombre: "",
  logo: "",
  deportes: [],
  tenis: false,
  padel: false,
  canchas: [],
  canchasTenis: [],
  canchasPadel: [],
  descripcion: "",
  horarios: [],
  direccion: "",
  contactos: [],
  bar: false,
  vestuarios: false,
  identificacionFiscal: "",
  domicilioFiscal: "",
  constanciaInscripcion: "",
  step1V: false,
  step2V: false,
  step3V: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LLENAR_STEP_1":
      const deportesNuevos = [];
      const contactosNuevos = [];
      if (action.payload.tenis) deportesNuevos.push("tenis");
      if (action.payload.tenis) deportesNuevos.push("padel");
      if (action.payload.contacto)
        contactosNuevos.push(
          "+" + action.payload.codigoPais + action.payload.contacto
        );
      return {
        ...state,
        ...action.payload,
        deportes: deportesNuevos,
        contactos: contactosNuevos,
      };
    case "LLENAR_STEP":
      return { ...state, ...action.payload };
    case "LLENAR_STEP_3":
      const canchasNuevas = [];
      if (action.payload.canchasTenis)
        canchasNuevas.push(...action.payload.canchasTenis);
      if (action.payload.canchasPadel)
        canchasNuevas.push(...action.payload.canchasPadel);
      return { ...state, ...action.payload, canchas: canchasNuevas };
    case "STEP_1V":
      return { ...state, step1V: action.payload };
    case "STEP_2V":
      return { ...state, step2V: action.payload };
    case "STEP_3V":
      return { ...state, step3V: action.payload };
    case "LIMPIAR_FORMULARIO":
      return INITIAL_STATE;
    default:
      return state;
  }
};
