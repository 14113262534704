import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Box, Fade, IconButton, MenuItem, TextField } from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addHours } from "date-fns/esm";
import deLocale from "date-fns/locale/es";
import React, { useState } from "react";
import { getDayName, organizeDays } from "../../utils/helperFunctions";

const AddDay = ({ addMore, setAddMore, arrayData, setArrayData }) => {
  //states
  const [selectedDay, setSelectedDay] = useState("");
  const [start, setStart] = useState(new Date("", "", "", 8, 0));
  const [end, setEnd] = useState(new Date("", "", "", 23, 0));
  //
  const days = [0, 1, 2, 3, 4, 5, 6];
  const enabledDays = arrayData?.map((d) => d.dia); //get only days number
  const intersection = days.filter((e) => !enabledDays.includes(e));
  const handleChange = (event) => {
    setSelectedDay(event.target.value);
  };
  const handleStart = (e) => {
    setStart(e);
  };
  const handleEnd = (e) => {
    setEnd(e);
  };
  const handleSave = () => {
    let startHour = start.getHours();
    let startMinutes = start.getMinutes();
    let endHour = end.getHours();
    let endMinutes = end.getMinutes();

    //set the new object
    const copySchedule = [...arrayData];
    let newDay = {
      dia: selectedDay,
      inicio: {
        hora: startHour,
        minutos: startMinutes,
      },
      final: {
        hora: endHour,
        minutos: endMinutes,
      },
    };
    copySchedule.push(newDay);
    setArrayData(organizeDays(copySchedule));
    setAddMore(false);
  };
  console.log("picked ", selectedDay);
  return (
    <Fade in={addMore} unmountOnExit>
      <Box sx={root}>
        <Box sx={left}>
          <TextField
            id="outlined-select-currency"
            select
            label="Día"
            value={selectedDay}
            onChange={handleChange}
            size={"small"}
            sx={{ mt: 1 }}
          >
            {intersection?.map((option) => (
              <MenuItem value={option}>{getDayName(option)}</MenuItem>
            ))}
          </TextField>

          <Box sx={pickersContainer}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={deLocale}
            >
              <MobileTimePicker
                name="horaInicio"
                required
                label="Inicio"
                value={start}
                minTime={new Date("", "", "", 6, 0)}
                // maxTime={new Date('','','',22,0)}
                minutesStep={30}
                onChange={handleStart}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={deLocale}
            >
              <MobileTimePicker
                name="horaFinal"
                required
                label="Final"
                value={end}
                minTime={addHours(start, 1)}
                minutesStep={30}
                onChange={handleEnd}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box sx={right}>
          <IconButton
            sx={{ color: "#32c06d" }}
            disabled={selectedDay === ""}
            onClick={handleSave}
          >
            <TaskAltIcon sx={{ fontSize: "2em" }} />
          </IconButton>
        </Box>
      </Box>
    </Fade>
  );
};
const root = {
  display: "flex",
  flexDirection: "row",
};
const left = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  mt: 5,
};
const right = {
  margin: "auto",
};
const pickersContainer = {
  display: "flex",
  flexDirection: "row",
  mt: 5,
};
export default AddDay;
