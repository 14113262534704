const INITIAL_STATE = {
  data: [],
  total: 0,
  pagado: false,
  cargandoTotal: false,
  cargando: false,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FACTURACION_DATA":
      return { ...state, data: action.payload };
    case "FACTURACION_TOTAL":
      return { ...state, total: action.payload };
    case "FACTURACION_PAGADO":
      return { ...state, pagado: action.payload };
    case "FACTURACION_CARGANDO_TOTAL":
      return { ...state, cargandoTotal: action.payload };
    case "FACTURACION_CARGANDO":
      return { ...state, cargando: action.payload };
    case "FACTURACION_ERROR":
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
