import CreditCardIcon from "@mui/icons-material/CreditCard";
import {
  Box,
  Divider,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns/esm";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { printNombreCancha } from "../../Handle/Handle";
import { formatPriceToPay } from "../../utils/helperFunctions";
import PaginationMobile from "../Turnos/PaginationMobile";

const TableFacturation = ({ tableHead, data }) => {
  //redux
  const canchas = useSelector((state) => state.clubes.miClub.canchas);
  const total = useSelector((state) => state.facturacion.total);
  //state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "flex-end",
          mr: "2em",
          mb: "1em",
        }}
      >
        <CreditCardIcon color="primary" sx={{ marginRight: "0.5em" }} />
        <Typography variant="title">Total a pagar: $ {total}</Typography>
      </Box>
      <Divider sx={{ display: { xs: "flex", md: "none" } }} />

      {(rowsPerPage > 0
        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : data
      ).map((row, i) => (
        <>
          <List sx={root} disablePadding>
            <ListItem
              sx={{ backgroundColor: i % 2 === 0 ? "#fbf1dc" : "#fff" }}
            >
              {/**CONTAINER */}
              <Box sx={container}>
                {console.log(row)}
                {/**LEFT */}
                <Box sx={left}>
                  <Typography variant="title2">
                    {printNombreCancha(canchas, row.cancha)}
                  </Typography>
                </Box>
                {/**RIGHT */}
                <Box sx={right}>
                  <Box sx={rightItem}>
                    <Typography variant="title2">$ {row.precio}</Typography>
                    <Typography variant="title2">
                      % {row.comision.toFixed(2)}
                    </Typography>
                  </Box>

                  <Box sx={rightItem}>
                    <Typography variant="title2">
                      {format(new Date(row.fechaInicio), "dd/MM/y")}
                    </Typography>
                    <Typography variant="title2">
                      {row.horaInicio} hs.
                    </Typography>
                  </Box>

                  <Box sx={rightItem} style={{ alignItems: "center" }}>
                    <Typography variant="title2" sx={{ marginLeft: 5 }}>
                      A pagar
                    </Typography>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="title2" sx={{ marginLeft: 5 }}>
                        {row.señado
                          ? "-"
                          : `$ ${formatPriceToPay(
                              row.precio,
                              row.comision / 1.75
                            )}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </ListItem>
            <Divider sx={{ display: { sm: "flex", md: "none" } }} />
          </List>
        </>
      ))}
      <PaginationMobile
        page={page}
        setPage={(e) => setPage(e)}
        numberOfItems={data?.length}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(e) => setRowsPerPage(e)}
      />
      <TableContainer sx={tableContainerStyle}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={5} />
              <TableCell sx={totalStyle}>Total</TableCell>
              <TableCell sx={totalPriceStyle}>$ {total}</TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: "#ffeccc" }}>
              {tableHead.map((element) => (
                <TableCell>{element}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row) => (
              <TableRow key={row.id}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ minWidth: "2em" }}
                >
                  {format(new Date(row.fechaInicio), "dd/MM/y")}
                </TableCell>
                <TableCell>{row.horaInicio}</TableCell>
                <TableCell>{printNombreCancha(canchas, row.cancha)}</TableCell>
                <TableCell>$ {row.precio}</TableCell>
                <TableCell>% {row.comision.toFixed(2)}</TableCell>
                <TableCell>{row.señado ? "Si" : "No"}</TableCell>
                <TableCell>
                  {row.señado
                    ? "-"
                    : row.moneda === "ars"
                    ? `$ ${formatPriceToPay(row.precio, row.comision / 1.75)}`
                    : `$ ${formatPriceToPay(row.precio, row.comision / 1.75)}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ page }) => {
                  return `Pagina: ${page}`;
                }}
                backIconButtonProps={{
                  color: "default",
                }}
                nextIconButtonProps={{ color: "default" }}
                showFirstButton={true}
                showLastButton={true}
                labelRowsPerPage={<span>Filas:</span>}
                sx={paginationStyle}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
const tableContainerStyle = {
  display: { xs: "none", sm: "none", md: "flex" },
};
const totalStyle = {
  backgroundColor: "#ffeccc",
  borderTop: "solid",
  borderTopColor: "#e0e0e0",
  borderTopWidth: 1,
};
const totalPriceStyle = {
  borderTop: "solid",
  borderTopColor: "#e0e0e0",
  borderTopWidth: 1,
};
const container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
};

const left = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
const right = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const rightItem = {
  display: "flex",
  flexDirection: "column",
  marginLeft: 5,
  minWidth: "7.5em",
};
const root = {
  display: { sm: "flex", md: "none" },
  backgroundColor: "#fff",
};
const paginationStyle = {
  ".MuiTablePagination-toolbar": {
    backgroundColor: "#fff",
  },
  ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
    color: "GrayText",
  },
};
export default TableFacturation;
