const INITIAL_STATE = {
  data: [],
  selected: null,
  cargando: false,
  cargandoBorrar: false,
  error: "",
  errorCrear: "",
  errorBorrar: "",
  mostrarConfirmacionBorrar: false,
  formulario: {
    nombre: "", //
    descripcion: "",
    director: "", //
    codigoPais: "549", //
    contacto: "", //
    deporte: "tenis", //
    tipo: "torneo", //
    modalidad: "singles",
    genero: "femenino",
    edadMinima: 18,
    edadMaxima: 99,
    programacion: "programacion",
    horaInicio: "",
    fechaInicio: "", //
    fechaFinalizacion: "", //
    fechaTopeInscripcion: "", //
    cronograma: "semanal",
    cantidadMaxInscriptos: 120,
    valorInscripcion: 0, //
    precioPorPartido: 0,
    observaciones: "",
    premios: "",
    formato: "round-robin",
    cantidadSets: 3,
    gamesPorSet: 6,
    desempateSet: "tiebreak",
    definitorio: "set",
    tiebreakDefinitorio: null,
    torneos: [], //
    step0V: false,
    step1V: false,
    step2V: false,
    step3V: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TORNEOS_DATA":
      return { ...state, data: action.payload };
    case "TORNEOS_SELECTED":
      return { ...state, selected: action.payload };
    case "TORNEOS_BORRAR":
      return {
        ...state,
        data: [state.data.filter((e) => e._id !== action.payload)],
      };
    case "TORNEOS_CONFIRMACION_BORRAR":
      return { ...state, mostrarConfirmacionBorrar: action.payload };
    case "TORNEOS_CARGANDO":
      return { ...state, cargando: action.payload };
    case "TORNEOS_CARGANDO_BORRAR":
      return { ...state, cargandoBorrar: action.payload };
    case "TORNEOS_ERROR":
      return { ...state, error: action.payload };
    case "TORNEOS_ERROR_CREAR":
      return { ...state, errorCrear: action.payload };
    case "TORNEOS_ERROR_BORRAR":
      return { ...state, errorBorrar: action.payload };
    case "TORNEOS_FORMULARIO":
      return {
        ...state,
        formulario: { ...state.formulario, ...action.payload },
      };
    case "TORNEOS_STEP_0":
      return {
        ...state,
        formulario: { ...state.formulario, step0V: action.payload },
      };
    case "TORNEOS_STEP_1":
      return {
        ...state,
        formulario: { ...state.formulario, step1V: action.payload },
      };
    case "TORNEOS_STEP_2":
      return {
        ...state,
        formulario: { ...state.formulario, step2V: action.payload },
      };
    case "TORNEOS_STEP_3":
      return {
        ...state,
        formulario: { ...state.formulario, step3V: action.payload },
      };
    case "TORNEOS_FORMULARIO_NIVELES":
      return {
        ...state,
        formulario: { ...state.formulario, torneos: action.payload },
      };
    default:
      return state;
  }
};
