import PendingActionsIcon from "@mui/icons-material/PendingActions";
import {
  Avatar,
  Box,
  ButtonBase,
  CircularProgress,
  Container,
  Fade,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import deLocale from "date-fns/locale/es";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTurnos } from "../../actions/turnos";
import { primeraLetraMayuscula } from "../../Handle/Handle";
import CreacionExitosa from "../Modals/CreacionExitosa";
import TituloPantalla from "../Titulos/TituloPantalla";
import ScheduleModal from "./ScheduleModal";

const padelImg =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzthFYfGDV-lkXBaQqq_TOuqFXJhMb8wiuOg&usqp=CAU";
const tenisImg =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9Nd9-tK4hmUQqE3kSVt8PpNBWVaSSWCljNA&usqp=CAU";

const TurnoDetalle = () => {
  const navigate = useNavigate();
  //redux
  const club = useSelector((state) => state.clubes.miClub);
  const loading = useSelector((state) => state.turnos.cargando);
  //states
  const [canchaSelected, setCanchaSelected] = useState("");
  const [diaSelected, setDiaSelected] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [mostrarModal, setMostrarModal] = useState(false);
  //
  const rangePrice = [500, 5000];
  const time = "";
  const type = "";
  const pasados = false;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getTurnos(
        diaSelected,
        rangePrice,
        time,
        canchaSelected._id,
        type,
        pasados
      )
    );
  }, [diaSelected, canchaSelected]);
  const imageAvatar = canchaSelected.deporte === "padel" ? padelImg : tenisImg;

  const handleCloseModal = () => {
    setMostrarModal(false);
    navigate("/turnos/actuales");
  };

  return (
    <>
      <CreacionExitosa
        open={mostrarModal}
        onClose={handleCloseModal}
        textButton="Ver Turnos"
        titulo="Publicaste nuevos turnos"
        texto="Los jugadores podrán ver los turnos y reservarlo mediante la app de Flobi!"
        setOpen={setMostrarModal}
      />
      <Box className="main">
        <TituloPantalla titulo="Crear Turno" />
        <Container component="main" maxWidth="sm">
          <Box sx={root}>
            {/* {canchaSelected !== "" ? (
              <Fade in={canchaSelected !== ""} unmountOnExit>
                <Avatar
                  sx={{ mb: 10, width: "8em", height: "8em" }}
                  src={imageAvatar}
                />
              </Fade>
            ) : null} */}
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                {/**CONTENT */}
                <Box sx={itemStyle}>
                  <Typography
                    variant="title"
                    color="common.negroClaro2"
                    sx={{ mb: "1em" }}
                  >
                    Selecciona una fecha
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={deLocale}
                  >
                    <MobileDatePicker
                      label="Fecha"
                      value={diaSelected}
                      inputFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setDiaSelected(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ width: 210 }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>

                <Box sx={itemStyle}>
                  <Typography
                    variant="title"
                    color="common.negroClaro2"
                    sx={{ mb: "1em" }}
                  >
                    Selecciona una cancha
                  </Typography>
                  <Box>
                    <FormControl size="small" sx={{ width: 210 }}>
                      <Select
                        name="cancha"
                        required
                        labelId="cancha-label"
                        id="cancha-select"
                        value={canchaSelected}
                        onChange={(e) => {
                          setCanchaSelected(e.target.value);
                        }}
                      >
                        {club.canchas.map((c, i) => (
                          <MenuItem key={i} value={c}>
                            {primeraLetraMayuscula(c.nombre)} -{" "}
                            {primeraLetraMayuscula(c.deporte)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                {canchaSelected !== "" ? (
                  <Box sx={scheduleRoot}>
                    <ButtonBase
                      sx={scheduleContainer}
                      onClick={() => setOpenModal(true)}
                    >
                      <PendingActionsIcon
                        color="primary"
                        sx={{ height: "2em", width: "2em" }}
                      />
                      <Typography variant="title">Crear turnos </Typography>
                    </ButtonBase>
                  </Box>
                ) : null}
              </Grid>
              {/**FIN */}
              {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </Box>
        </Container>

        <ScheduleModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          selected={canchaSelected}
          diaSelected={diaSelected}
          modal={setMostrarModal}
        />
      </Box>
    </>
  );
};

const root = {
  marginTop: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
const itemStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  mb: "1em",
};
const scheduleRoot = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
};
const scheduleContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: 210,
  py: 2,
};
export default TurnoDetalle;
