import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const ConfirmarBorrar = ({
  open,
  titulo,
  texto,
  handleCancelar,
  handleBorrar,
  cargando,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleCancelar}
      aria-labelledby="modal-confirmar-borrar-titulo"
      aria-describedby="modal-confirmar-borrar-descripcion"
    >
      <DialogTitle id="modal-creacion-exitosa-titulo">{titulo}</DialogTitle>
      <DialogContent>
        <DialogContentText id="modal-creacion-exitosa-descripcion">
          {texto}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={cargando} onClick={handleCancelar}>
          Cancelar
        </Button>
        <LoadingButton loading={cargando} onClick={handleBorrar}>
          Borrar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmarBorrar;
