import axios from "axios";
import { endOfDay, startOfDay, subHours } from "date-fns";
import servidor from "../../api/servidor";
import { errorDispatch } from "../../utils/error";
import { errorGlobal, errorLocal } from "../error";

export const crearTurno = (data, callback) => async (dispatch) => {
  dispatch({ type: "TURNOS_CARGANDO", payload: true });

  try {
    const respuesta = await servidor.post("/v2/turnos", data);

    dispatch({ type: "TURNOS_SELECTED", payload: respuesta.data.data });

    callback(true);
  } catch (err) {
    dispatch(errorGlobal(err));
  }
  dispatch({ type: "TURNOS_CARGANDO", payload: false });
};

export const getTurnos = (
  fecha,
  rangoPrecio,
  hora = "",
  cancha = "",
  estado = "",
  pasados = false
) => async (dispatch) => {
  dispatch({ type: "TURNOS_CARGANDO", payload: true });
  dispatch({ type: "TURNOS_ERROR", payload: false });

  try {
    const fechaOperator = pasados ? "[lte]" : "[gte]";
    const fechaHoy = subHours(new Date(), 1);
    const fechaQuery = fecha
      ? `fechaInicio[gte]=${startOfDay(fecha)}&fechaInicio[lte]=${endOfDay(
          fecha
        )}`
      : `fechaInicio${fechaOperator}=${fechaHoy}`;
    const estadoQuery =
      estado === "disponible"
        ? "disponible=true"
        : estado === "reservado"
        ? "disponible=false"
        : "";
    const precioQuery = `precio[gte]=${rangoPrecio[0]}&precio[lte]=${rangoPrecio[1]}`;
    const horaQuery = hora ? `horaInicio=${hora}` : "";
    const canchaQuery = cancha ? `cancha=${cancha}` : "";
    const queryStr =
      `${fechaQuery}&` +
      `${estadoQuery}&` +
      `${precioQuery}&` +
      `${horaQuery}&` +
      canchaQuery;

    const sort = pasados ? "-fechaInicio" : "fechaInicio";
    const respuesta = await servidor.get(
      `/v2/turnos/?sort=${sort}&populate=reservas&${queryStr}`
    );

    dispatch({ type: "TURNOS_DATA", payload: respuesta.data.data });
  } catch (err) {
    dispatch(errorLocal(err, "TURNOS_ERROR"));
  }
  dispatch({ type: "TURNOS_CARGANDO", payload: false });
};

export const borrarTurno = (id) => async (dispatch) => {
  console.log("hola");
  dispatch({ type: "TURNOS_CARGANDO", payload: true });

  try {
    await servidor.delete(`/v2/turnos/${id}`);

    dispatch({ type: "TURNOS_BORRAR", payload: id });
  } catch (err) {
    dispatch(errorGlobal(err));
  }

  dispatch({ type: "TURNOS_CARGANDO", payload: false });
};

export const getTurno = (id) => async (dispatch) => {
  dispatch({ type: "TURNOS_CARGANDO", payload: true });
  dispatch({ type: "TURNOS_ERROR_BORRAR", payload: "" });

  try {
    const response = await servidor.get(`/v2/turnos/${id}`);
    dispatch({ type: "TURNO_DETALLE", payload: response.data.data });
  } catch (err) {
    console.log(err);
    errorDispatch(err, dispatch, "TURNOS_ERROR");
  }

  dispatch({ type: "TURNOS_CARGANDO", payload: false });
};

export const multipleTurnos = (data, callback) => async (dispatch) => {
  dispatch({ type: "TURNOS_CARGANDO", payload: true });

  await axios
    .all(data.map((item) => servidor.post("/v2/turnos", item)))
    .then(
      axios.spread((...responses) => {
        responses.forEach((res) => console.log("success"));
        console.log("submitted all axios calls");
        callback(true);
      })
    )
    .catch((err) => {
      dispatch(errorGlobal(err));
      console.log(err.response);
    });

  dispatch({ type: "TURNOS_CARGANDO", payload: false });
};
