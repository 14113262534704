const INITIAL_STATE = {
  data: [],
  selected: null,
  turnDetail: {},
  error: "",
  errorTotal: false,
  cargando: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TURNOS_DATA":
      return { ...state, data: action.payload };
    case "TURNOS_SELECTED":
      return { ...state, selected: action.payload };
    case "TURNOS_TOTAL":
      return { ...state, total: action.payload };
    case "TURNOS_BORRAR":
      return {
        ...state,
        data: [...state.data.filter((e) => e.id !== action.payload)],
      };
    case "TURNOS_ERROR":
      return { ...state, error: action.payload };
    case "TURNOS_CARGANDO":
      return { ...state, cargando: action.payload };
    case "TURNOS_LIMPIAR_SELECTED":
      return { ...state, selected: null };
    case "TURNO_DETALLE":
      return { ...state, turnDetail: action.payload };
    default:
      return state;
  }
};
