import { useState, useEffect } from "react";

export const getDayName = (number) => {
  switch (number) {
    case 0:
      return "Domingo";
    case 1:
      return "Lunes";
    case 2:
      return "Martes";
    case 3:
      return "Miércoles";
    case 4:
      return "Jueves";
    case 5:
      return "Viernes";
    case 6:
      return "Sábado";
    default:
      return "error";
  }
};
export const organizeDays = (array) => {
  return array.sort((a, b) => a.dia - b.dia);
};
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
export const getZero = (number) => {
  if (number < 10) {
    return `0${number}`;
  } else return number;
};
export const formatPriceToPay = (price, commission) => {
  return (price * (commission / 100)).toFixed(2);
};
export const getMonthName = (number) => {
  switch (number) {
    case 0:
      return "Enero";
    case 1:
      return "Febrero";
    case 2:
      return "Marzo";
    case 3:
      return "Abril";
    case 4:
      return "Mayo";
    case 5:
      return "Junio";
    case 6:
      return "Julio";
    case 7:
      return "Agosto";
    case 8:
      return "Septiembre";
    case 9:
      return "Octubre";
    case 10:
      return "Noviembre";
    case 11:
      return "Diciembre";
    default:
      return "error";
  }
};

export function useWindowsSize() {
  const [windowsSize, setWindowsSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowsSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowsSize;
}
export const setTime = (date, currentDay) => {
  let hour = date.split(":")[0];
  let minutes = date.split(":")[1];
  const year = new Date(currentDay).getFullYear();
  const month = new Date(currentDay).getMonth();
  const day = new Date(currentDay).getDate();
  const time = new Date(year, month, day, hour, minutes);
  return time;
};
