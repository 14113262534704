import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/turnos");
  }, []);

  // const usuario = useSelector((state) => state.usuario.data);
  // const miClub = useSelector((state) => state.clubes.miClub);

  // function handleDate(usuario) {
  //   console.log(usuario);
  // }

  // function clubregister() {
  //   navigate("/clubregister");
  // }

  // function elegirClub() {
  //   navigate("/elegirclub");
  // }

  // useEffect(() => {
  //   if (!usuario) {
  //     navigate("/login");
  //   }
  //   if (usuario) {
  //     if (!miClub) {
  //       navigate("/elegirClub");
  //     }
  //   }
  // }, []);

  return (
    <>
      {/* <Box className="main">
        <h1>Hola {usuario && usuario.nombre}</h1>
        <button onClick={handleDate}>click </button>
        <button onClick={clubregister}>register </button>
        <button onClick={elegirClub}>elegir Club </button>
      </Box> */}
    </>
  );
};
export default Home;
