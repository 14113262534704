import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const TituloPantalla = ({ titulo }) => {
  const navigate = useNavigate();

  const handleVolver = () => {
    navigate(-1);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        sx={{ mb: "3.5em" }}
      >
        <Grid item>
          <IconButton
            aria-label="volver"
            onClick={handleVolver}
            color="primary"
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography sx={{ color: "common.negroClaro3" }} variant="h2">
            {titulo}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default TituloPantalla;
