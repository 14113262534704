import {
  Box,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import styles from "../../styles/DropdownStyle.module.css";

const DropdownIcon = ({ array, icon, setValue }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    setValue(e);
    console.log(e);
    setOpen(false);
    return;
  };
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box className={styles.container}>
        <button onClick={() => setOpen(!open)} style={styleButton}>
          {icon}
        </button>
        {open && (
          <Box className={styles.dropdownIcon}>
            <List>
              {array.map((e) => (
                <ListItemButton onClick={() => handleClick(e)}>
                  <ListItemText
                    primary={e}
                    primaryTypographyProps={{ fontSize: "1.3rem" }}
                  />
                </ListItemButton>
              ))}
            </List>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};
const styleButton = {
  textTransform: "none",
  padding: 0,
  border: "none",
  background: "none",
  cursor: "pointer",
  //background:'transparent'
};

export default DropdownIcon;
