import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import React from "react";
import StyledBadge from "./StyledBadge";

const HeaderRight = ({ onClick, username, photoURL }) => {
  return (
    <Box sx={container}>
      {/* Arrow in mobile size */}
      <Box sx={{ display: { xs: "flex", sm: "none" } }}>
        <IconButton onClick={onClick}>
          <ArrowBackIcon />
        </IconButton>
      </Box>

      <Box sx={profile}>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <Avatar src={photoURL} style={{ marginRight: 10 }} />
        </StyledBadge>

        <Typography
          variant="title" //<--fontSize
          color="common.negro"
        >
          {username}
        </Typography>
      </Box>
      <div />
    </Box>
  );
};
const container = {
  backgroundColor: "#f0f2f5", //f9ede1 f0f2f5
  height: 40,
  justifyContent: "space-between",
  padding: 5,
  display: "flex",
};
const profile = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
export default HeaderRight;
