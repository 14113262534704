import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";

const PaginationMobile = ({
  page,
  setPage,
  numberOfItems,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const totalPages = Math.trunc((numberOfItems - 1) / rowsPerPage);
  // Math.trunc() remove decimal
  const handleFirstPage = () => {
    if (page !== 0) {
      setPage(0);
    }
  };
  const handlePrevPage = () => {
    let prevPage = page - 1;
    setPage(prevPage);
  };
  const handleNextPage = () => {
    let nextPage = page + 1;
    setPage(nextPage);
  };
  const handleLastPage = () => {
    setPage(totalPages);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  return (
    <Box sx={right}>
      <Box sx={{ marginRight: "1em" }}>
        <Typography variant="title">Filas: </Typography>
      </Box>
      <FormControl size="small" sx={{ mr: '1em'}}>
        <Select
          name="filas"
          required
          labelId="filas-label"
          id="filas-select"
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[5, 10, 25].map((c, i) => (
            <MenuItem key={i} value={c}>
              {c}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ marginRight: "1em" }}>
        <Typography variant="title">
          Pagina {page}
        </Typography>
      </Box>

      <IconButton onClick={handleFirstPage} disabled={page === 0}>
        <FirstPageIcon />
      </IconButton>

      <IconButton onClick={handlePrevPage} disabled={page === 0}>
        <NavigateBeforeIcon />
      </IconButton>

      <IconButton onClick={handleNextPage} disabled={page === totalPages}>
        <NavigateNextIcon />
      </IconButton>

      <IconButton onClick={handleLastPage} disabled={page === totalPages}>
        <LastPageIcon />
      </IconButton>
    </Box>
  );
};
const right = {
  display: { xs: "flex", sm: "flex", md: "none" },
  justifyContent: "flex-end",
  marginRight: "1em",
  alignItems: "center",
};

export default PaginationMobile;
