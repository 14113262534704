import servidor from "../../api/servidor";

export const getCheckout = () => async (dispatch) => {
  dispatch({ type: "CLIENTE_CHECKOUT_CARGANDO", payload: true });
  dispatch({ type: "CLIENTE_CHECKOUT_ERROR", payload: false });

  try {
    const respuesta = await servidor.post(
      "/v2/payments/club/checkout-session/activation"
    );

    dispatch({
      type: "CLIENTE_CHECKOUT_SESSION",
      payload: respuesta.data.data,
    });
  } catch (err) {
    console.log(err.response.data);
    dispatch({ type: "CLIENTE_CHECKOUT_ERROR", payload: true });
  }
  dispatch({ type: "CLIENTE_CHECKOUT_CARGANDO", payload: false });
};

export const activarMetodoPago = (sessionId) => async (dispatch) => {
  dispatch({ type: "CLIENTE_CARGANDO", payload: true });
  dispatch({ type: "CLIENTE_ERROR", payload: false });

  try {
    const respuesta = await servidor.post("/v2/clients/activatePaymentMethod", {
      sessionId,
    });

    dispatch({ type: "CLIENTE_DATA", payload: respuesta.data.data });
  } catch (err) {
    console.log(err.response.data);
    dispatch({ type: "CLIENTE_ERROR", payload: true });
  }
  dispatch({ type: "CLIENTE_CARGANDO", payload: false });
};
