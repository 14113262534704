import DoneIcon from "@mui/icons-material/Done";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useWindowsSize } from "../../utils/helperFunctions";
import AudioPlayer from "./AudioPlayer";

const timeFormated = (TIMESTAMP) => {
  const date = new Date(TIMESTAMP);
  const hour = date.getHours() === 0 ? "00" : date.getHours();
  const minutes =
    date.getMinutes() === 0
      ? "00"
      : date.getMinutes() < 10
      ? `0${date.getMinutes()}`
      : date.getMinutes();
  const formated = `${hour}:${minutes}`;
  return formated;
};

const Messages = ({ id, messages, currentUser, otherUser, photoURL }) => {
  const size = useWindowsSize();
  const container = {
    margin: 2,
    display: "flex",
    flexDirection: currentUser === otherUser ? "row-reverse" : "row",
    marginRight: "5vw",
    marginLeft: "5vw",
  };
  const messageContainer = {
    backgroundColor: currentUser === otherUser ? "#f9ede1" : "#dfe9fb",
    padding: 3,
    borderTopLeftRadius: currentUser === otherUser ? 10 : 0,
    borderTopRightRadius: currentUser === otherUser ? 0 : 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    maxWidth: size.width >= 600 ? "40vw" : "60vw",
    textAlign: "left",
    wordBreak: "break-word",
  };
  const timeAndTick = {
    display: "flex",
    justifyContent: currentUser === otherUser ? "flex-end" : "flex-start",
    alignItems: "center",
  };
  const iconStyle = {
    color: "#000",
    fontSize: 10,
    marginLeft: -6,
  };
  const audioContainer = {
    backgroundColor: "#dfe9fb", //f0f2f5
    padding: 1,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: { xm: 200, sm: 260 },
  };
  return (
    <Box sx={container} key={id}>
      {messages.tipoMensaje === "texto" ? (
        <Box sx={messageContainer}>
          <Typography
            variant="title2" //<--fontSize
            color="common.negro"
          >
            {messages.texto}
          </Typography>

          <Box sx={timeAndTick}>
            <Typography
              variant="subTitle"
              color="common.negro"
              sx={{ marginRight: 4 }}
            >
              {messages.createdAt ? timeFormated(messages.createdAt) : ""}
            </Typography>

            {currentUser === otherUser &&
              [1, 2].map((e) => {
                return <DoneIcon style={iconStyle} />;
              })}
          </Box>
        </Box>
      ) : (
        <Box sx={audioContainer}>
          <AudioPlayer
            urlAudio={messages?.audio?.url}
            urlPhoto={photoURL}
            time={messages.createdAt ? timeFormated(messages.createdAt) : ""}
            id={id}
          />
        </Box>
      )}
    </Box>
  );
};

export default Messages;
