const INITIAL_STATE = {
  data: null,
  errorCrear: "",
  errorGet: false,
  cargando: false,
  cargandoCrear: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case "RECLAMOS_AGREGAR_RECLAMO":
    //   return { ...state, data: [...state.data, action.payload] };
    case "RECLAMOS_GET":
      return { ...state, data: action.payload };
    case "RECLAMOS_ERROR_CREAR":
      return { ...state, errorCrear: action.payload };
    case "RECLAMOS_ERROR_GET":
      return { ...state, errorGet: action.payload };
    case "RECLAMOS_CARGANDO":
      return { ...state, cargando: action.payload };
    case "RECLAMOS_CARGANDO_CREAR":
      return { ...state, cargandoCrear: action.payload };
    default:
      return state;
  }
};
