import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import React from "react";

const TabRow = ({ leftComponent, centerComponent, rightComponent }) => {
  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} md={2}>
          {leftComponent}
        </Grid>
        <Grid item xs={10} md={9}>
          {centerComponent}
        </Grid>
        <Grid item xs={2} md={1}>
          {rightComponent}
        </Grid>
      </Grid>
    </>
  );
};

export default TabRow;
