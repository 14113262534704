import servidor from "../../api/servidor";
import { setMonth, startOfMonth, endOfDay } from "date-fns";
import { errorLocal } from "../error";

export const getFacturacion = (mes) => async (dispatch) => {
  dispatch({ type: "FACTURACION_CARGANDO", payload: true });
  dispatch({ type: "FACTURACION_ERROR", payload: false });

  try {
    const startDay = startOfMonth(new Date());
    const thisMonth = setMonth(startDay, mes);
    const nextMonth = setMonth(startDay, mes + 1);

    const respuesta = await servidor.get(
      `/v2/turnos?fechaInicio[gte]=${thisMonth}&fechaFinal[lt]=${nextMonth}&disponible=false&sort=fechaInicio&populate=reservas`
    );

    const { data } = respuesta.data;

    let reservas = [];

    for (let i = 0; i < data.length; i++) {
      console.log(data[i]);
      reservas.push({
        id: data[i].id,
        fechaInicio: data[i].fechaInicio,
        horaInicio: data[i].horaInicio,
        cancha: data[i].cancha,
        precio: data[i].reservas ? data[i].reservas[0].precio : 0,
        comision: data[i].reservas ? data[i].reservas[0].comision : 0,
        señado: data[i].reservas ? data[i].reservas[0].señado : false,
      });
    }

    dispatch({ type: "FACTURACION_DATA", payload: reservas });
  } catch (err) {
    dispatch(errorLocal(err, "FACTURACION_ERROR"));
  }

  dispatch({ type: "FACTURACION_CARGANDO", payload: false });
};

export const getTotal = (mes) => async (dispatch) => {
  try {
    const respuesta = await servidor.get(
      `/v2/invoices/myInvoices/invoice/${mes}`
    );

    dispatch({
      type: "FACTURACION_TOTAL",
      payload: respuesta.data.data.stripe.amount_due / 100,
    });

    const paid = respuesta.data.data.stripe.amount_remaining > 0 ? false : true;

    dispatch({ type: "FACTURACION_PAGADO", payload: paid });
  } catch (err) {}
};
