import TuneIcon from "@mui/icons-material/Tune";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addDays, format } from "date-fns";
import deLocale from "date-fns/locale/es";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTurnos } from "../../actions/turnos";
import TituloPantalla from "../Titulos/TituloPantalla";
import CustomTable from "./CustomTable";
import ErrorGet from "./ErrorGet";
import ModalFilter from "./ModalFilter";
import Loader from "../Loaders/Loader";
import EmptyMessage from "../Empty/EmptyMessage";

const Turnos = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //redux
  const turnos = useSelector((state) => state.turnos.data);
  const error = useSelector((state) => state.turnos.error);
  const errorBorrar = useSelector((state) => state.turnos.errorBorrar);
  const cargando = useSelector((state) => state.turnos.cargando);
  const canchas = useSelector((state) => state.clubes.miClub.canchas);
  const club = useSelector((state) => state.clubes.miClub);

  //states
  const [fecha, setFecha] = useState(new Date());
  const [verTodos, setVerTodos] = useState(false);
  const [mostrarError, setMostrarError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [time, setTime] = useState("");
  const [slot, setSlot] = useState("Todas");
  const [type, setType] = useState("");
  const [price, setPrice] = useState([500, 10000]);

  useEffect(() => {
    handleGetTurnos();
  }, [verTodos, fecha]);

  const handleGetTurnos = () => {
    const slotId = slot !== "Todas" ? JSON.parse(slot)._id : "";
    const timeValue = time ? format(time, "HH:mm") : "";
    if (verTodos) {
      dispatch(getTurnos("", price, timeValue, slotId, type, false));
    } else {
      dispatch(getTurnos(fecha, price, timeValue, slotId, type, false));
    }
  };

  const handleVerChange = (event) => {
    setVerTodos(event.target.checked);
  };

  const columns = [
    "Fecha",
    "Hora Inicio",
    " Hora Final",
    "Cancha",
    "Precio",
    "Estado",
    "Reservado por",
    "Saldo",
    "Acciones",
  ];
  return (
    <>
      <ModalFilter
        showModal={showModal}
        setShowModal={setShowModal}
        enableReservations={true}
        time={time}
        setTime={setTime}
        slot={slot}
        setSlot={setSlot}
        type={type}
        setType={setType}
        price={price}
        setPrice={setPrice}
        handleUpdate={handleGetTurnos}
      />

      <Box className="main">
        <TituloPantalla titulo="Turnos" />
        <Grid
          className="main"
          container
          item
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid container direction="row" alignItems="center" spacing={12}>
              <Grid item>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={deLocale}
                >
                  <MobileDatePicker
                    label="Fecha"
                    value={fecha}
                    disabled={verTodos}
                    inputFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    maxDate={addDays(new Date(), 14)}
                    views={["year", "month", "day"]}
                    onChange={(newValue) => {
                      setFecha(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Ver todos"
                    checked={verTodos}
                    onChange={handleVerChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            sx={{ mr: "2em", mt: { xs: "1.5em", md: 0 }, p: 5 }}
            justifyContent={{ xs: "space-between", md: "flex-end" }}
            display="flex"
          >
            <Button
              variant="contained"
              sx={buttonStyle}
              onClick={() => setShowModal(true)}
            >
              Filtros <TuneIcon sx={{ marginLeft: 5 }} />
            </Button>

            <Button
              variant="contained"
              onClick={() => navigate("/turnos/crearturno")}
            >
              Crear nuevo turno
            </Button>
          </Grid>
        </Grid>

        <Box sx={{ paddingTop: 5 }} />
        {error ? (
          <ErrorGet titulo={error} accion={handleGetTurnos} />
        ) : cargando ? (
          <Loader />
        ) : turnos.length === 0 ? (
          <EmptyMessage texto="No hay turnos publicados este día..." />
        ) : (
          <CustomTable tableHead={columns} data={turnos} />
        )}
      </Box>
    </>
  );
};
const buttonStyle = {
  backgroundColor: "#f0f2f5",
  color: "#000",
  "&:hover": { backgroundColor: "#ffeccc" },
  marginRight: "1em",
};

export default Turnos;
