export default (state = "", action) => {
  switch (action.type) {
    case "agregar_error":
      return action.payload;
    case "eliminar_error":
      return "";
    default:
      return state;
  }
};
