import React from "react";
import {
  Grid,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TituloPantalla from "../Titulos/TituloPantalla";

const Menu = () => {
  const navigate = useNavigate();

  const lista = [
    {
      titulo: "Crear Turno",
      body: "Publicá un turno de cancha disponible...",
      onPress: () => navigate("/turnos/crearturno"),
    },
    {
      titulo: "Mis Turnos",
      body: "Mirá tus turnos publicados activos...",
      onPress: () => navigate("/turnos/actuales"),
    },
    {
      titulo: "Reservas",
      body: "Mirá los turnos ya reservados...",
      onPress: () => navigate("/turnos/reservas"),
    },
    {
      titulo: "Historial",
      body: "Mirá todos los turnos que publicaste y ya vencieron...",
      onPress: () => navigate("/turnos/pasados"),
    },
  ];
  return (
    <>
      <Box className="main">
        <TituloPantalla titulo="Turnos" />
        <Paper
          sx={{
            paddingTop: "3em",
            paddingBottom: "3em",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-around"
            sx={{ pl: "0.5em", pr: "0.5em" }}
            spacing={32}
          >
            {lista.map((item, i) => (
              <Grid key={i} item xs={6} container justifyContent="center">
                <Card
                  elevation={6}
                  sx={{
                    height: "18em",
                    width: "36em",
                    backgroundColor: "common.principal",
                  }}
                >
                  <CardActionArea
                    sx={{ height: "100%", p: "1em" }}
                    onClick={item.onPress}
                  >
                    <CardContent
                      component={Grid}
                      justifyContent="center"
                      alignItems="center"
                      container
                    >
                      <Typography
                        variant="h4"
                        color="common.blanco"
                        textAlign="center"
                      >
                        {item.titulo}
                      </Typography>
                    </CardContent>
                    <Typography
                      variant="body1"
                      color="common.grisClaro4"
                      textAlign="center"
                      sx={{
                        display: { xs: "none", sm: "none", md: "inherit" },
                      }}
                    >
                      {item.body}
                    </Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default Menu;
