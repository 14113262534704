import EditIcon from "@mui/icons-material/Edit";
import SportsCricketIcon from "@mui/icons-material/SportsCricket";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { delay } from "../../utils/helperFunctions";
import LayoutTabs from "./LayoutTabs";
import CustomModal from "../Modals/CustomModal";
import TabRow from "./TabRow";
import { primeraLetraMayuscula } from "../../Handle/Handle";
import { actualizarClub } from "../../actions/clubes";

const printTipo = (tipo) => {
  switch (tipo) {
    case "polvo-ladrillo":
      return "Arcilla";
    case "dura":
      return "Dura";
    case "moqueta":
      return "Moqueta";
    case "madera":
      return "Madera";
    case "sintetico":
      return "Sintético";
    default:
      return "";
  }
};

const Canchas = () => {
  //state
  const [showModal, setShowModal] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [fetched, setFetched] = useState(false);
  //redux
  const dispatch = useDispatch();
  const club = useSelector((state) => state.clubes.miClub);
  const cargando = useSelector((state) => state.clubes.cargando);
  //effect
  useEffect(() => {
    if (fetched && !cargando) {
      setFetched(false);
      setShowModal(false);
    }
  }, [fetched, cargando]);
  //
  const findTenis = (element) => element.deporte === "tenis";
  const findPadel = (element) => element.deporte === "padel";
  const hasTenis = club.canchas.some(findTenis);
  const hasPadel = club.canchas.some(findPadel);
  const arrayPadel = club?.canchas?.filter((e) => e.deporte === "padel");
  const arrayTenis = club?.canchas?.filter((e) => e.deporte === "tenis");

  const openModal = (e) => {
    if (
      e === "precioHoraTenis" ||
      e === "precioHoraPadel" ||
      e === "precioLuzTenis" ||
      e === "precioLuzPadel"
    ) {
      return true;
    }
    return;
  };
  const titulos = (e) => {
    switch (e) {
      case "precioHoraTenis":
        return "Cambiar el precio de hora de Tenis";
      case "precioHoraPadel":
        return "Cambiar el precio de hora de Padel";
      case "precioLuzTenis":
        return "Cambiar el precio de luz de Tenis";
      case "precioLuzPadel":
        return "Cambiar el precio de luz de Padel";
    }
  };
  const handleUpdate = (e) => {
    let data = {};
    switch (e) {
      case "precioHoraTenis":
        data = { precioHoraTenis: newValue * 1 };
        break;
      case "precioHoraPadel":
        data = { precioHoraPadel: newValue * 1 };
        break;
      case "precioLuzTenis":
        data = { precioHoraLuzTenis: newValue * 1 };
        break;
      case "precioLuzPadel":
        data = { precioHoraLuzPadel: newValue * 1 };
        break;
    }

    dispatch(actualizarClub(data));

    setFetched(true);
  };

  return (
    <>
      <CustomModal
        setShowModal={setShowModal}
        showModal={openModal(showModal)}
        title={titulos(showModal)}
        apply={() => handleUpdate(showModal)}
        disabled={cargando}
        body={
          <Box>
            <TextField
              sx={{ display: "flex" }}
              id="outlined-multiline-static"
              size={"small"}
              value={newValue}
              onChange={(e) =>
                setNewValue(e.target.value.replace(/[^0-9]/g, ""))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              autoFocus
            />
            {cargando ? (
              <Box sx={loaderStyle}>
                <CircularProgress />
              </Box>
            ) : null}
          </Box>
        }
      />
      <LayoutTabs>
        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>Canchas</Typography>
          }
          centerComponent={
            <Box
              flexDirection={"column"}
              display="flex"
              justifyContent={"center"}
              sx={{ width: "100%" }}
            >
              {hasTenis ? (
                <Box
                  flexDirection={"row"}
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  mb={4}
                  sx={{ ml: { xs: "3em", sm: "3em" } }}
                >
                  <Typography variant="h5">Tenis</Typography>
                  <SportsTennisIcon
                    sx={{ fontSize: "2.5em", marginLeft: 10 }}
                  />
                </Box>
              ) : null}
              {arrayTenis.map((e) => (
                <Box
                  flexDirection={"row"}
                  display="flex"
                  justifyContent={"center"}
                  textAlign="center"
                  sx={{ ml: { xs: "3em", sm: "3em" } }}
                >
                  <Typography p={1}>
                    {`${primeraLetraMayuscula(e.nombre)} - Tipo: ${printTipo(
                      e.tipo
                    )} - Luz:${e?.luz ? " Si" : " No"}`}
                  </Typography>
                </Box>
              ))}
              {hasPadel ? (
                <Box
                  flexDirection={"row"}
                  display="flex"
                  justifyContent={"center"}
                  mb={4}
                  mt={4}
                  sx={{ ml: { xs: "3em", sm: "3em" } }}
                >
                  <Typography variant="h5">Padel</Typography>
                  <SportsCricketIcon
                    sx={{ fontSize: "2.5em", marginLeft: 10 }}
                  />
                </Box>
              ) : null}
              {arrayPadel.map((e) => (
                <Box
                  flexDirection={"row"}
                  display="flex"
                  justifyContent={"center"}
                  textAlign="center"
                  sx={{ ml: { xs: "3em", sm: "3em" } }}
                >
                  <Typography p={1}>
                    {`${primeraLetraMayuscula(e.nombre)} - Tipo: ${printTipo(
                      e.tipo
                    )} - Luz:${
                      e?.luz ? " Si" : " No"
                    } - Pared: ${primeraLetraMayuscula(e.pared)}`}
                  </Typography>
                </Box>
              ))}
            </Box>
          }
        />
        {club?.deportes.includes("tenis") ? (
          <>
            <div style={divider} />
            <TabRow
              leftComponent={
                <Typography color={"common.negroClaro3"}>
                  {`Precio de hora en Tenis`}
                </Typography>
              }
              centerComponent={<Typography>${club.precioHoraTenis}</Typography>}
              rightComponent={
                <IconButton
                  onClick={() => {
                    setNewValue(club.precioHoraTenis);
                    setShowModal("precioHoraTenis");
                  }}
                >
                  <EditIcon />
                </IconButton>
              }
            />
          </>
        ) : null}

        {club?.deportes.includes("padel") ? (
          <>
            <div style={divider} />
            <TabRow
              leftComponent={
                <Typography color={"common.negroClaro3"}>
                  {`Precio de hora en Padel`}
                </Typography>
              }
              centerComponent={<Typography>${club.precioHoraPadel}</Typography>}
              rightComponent={
                <IconButton
                  onClick={() => {
                    setNewValue(club.precioHoraPadel);
                    setShowModal("precioHoraPadel");
                  }}
                >
                  <EditIcon />
                </IconButton>
              }
            />
          </>
        ) : null}

        {club?.deportes.includes("tenis") ? (
          <>
            <div style={divider} />
            <TabRow
              leftComponent={
                <Typography color={"common.negroClaro3"}>
                  {`Precio de luz por hora en Tenis`}
                </Typography>
              }
              centerComponent={
                <Typography>${club.precioHoraLuzTenis}</Typography>
              }
              rightComponent={
                <IconButton
                  onClick={() => {
                    setNewValue(club.precioHoraLuzTenis);
                    setShowModal("precioLuzTenis");
                  }}
                >
                  <EditIcon />
                </IconButton>
              }
            />
          </>
        ) : null}

        {club?.deportes.includes("padel") ? (
          <>
            <div style={divider} />
            <TabRow
              leftComponent={
                <Typography color={"common.negroClaro3"}>
                  Precio de luz por hora en Padel
                </Typography>
              }
              centerComponent={
                <Typography>${club.precioHoraLuzPadel}</Typography>
              }
              rightComponent={
                <IconButton
                  onClick={() => {
                    setNewValue(club.precioHoraLuzPadel);
                    setShowModal("precioLuzPadel");
                  }}
                >
                  <EditIcon />
                </IconButton>
              }
            />
          </>
        ) : null}
      </LayoutTabs>
    </>
  );
};
const divider = {
  width: "100%",
  height: 1,
  backgroundColor: "#DDDDDD",
  margin: 10,
};
const loaderStyle = {
  display: "flex",
  justifyContent: "center",
  mt: 5,
};
export default Canchas;
