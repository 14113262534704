import { borrarTorneo } from "../actions/torneos";

export function isValidEmail(value) {
  let test = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
    value
  );
  return test;
}

export function isValidPass(value) {
  let test = /^(?=.*[A-Za-z])[A-Za-z\d@$!%*#?&]{8,30}$/.test(value);
  return test;
}

export function isValidName(value) {
  let test = /^([A-Za-z]).{2,30}/.test(value);
  return test && value.length < 31;
}

export function isValidCuit(value) {
  let test = /^\d{2}\-\d{8}\-\d{1}$/.test(value);
  return test;
}

export function isValidPhone(value) {
  let test = /^\d+$/.test(value);
  return test;
}

export function roundDate(date) {
  const coeff = 1000 * 60 * 30;
  return new Date(Math.round(date.getTime() / coeff) * coeff);
}

export function primeraLetraMayuscula(string) {
  const resultado = string.charAt(0).toUpperCase() + string.slice(1);

  return resultado;
}

export function printNombreCancha(canchas, canchaCandidata) {
  // console.log("canchas", canchas);
  // console.log("cancha candidata", canchaCandidata);
  for (let cancha of canchas) {
    if (cancha._id === canchaCandidata) {
      const string = `${primeraLetraMayuscula(
        cancha.nombre
      )} - ${primeraLetraMayuscula(cancha.deporte)}`;
      return string;
    }
  }

  return "";
}

export function calcularNivel(nivel, deporte) {
  let string = "";

  switch (nivel) {
    case 1:
      return deporte === "tenis" ? "Selección" : "1ra";
    case 2:
      return deporte === "tenis" ? "A" : "2da";
    case 3:
      return deporte === "tenis" ? "B1" : "3ra";
    case 4:
      return deporte === "tenis" ? "B2" : "4ta";
    case 5:
      return deporte === "tenis" ? "C1" : "5ta";
    case 6:
      return deporte === "tenis" ? "C2" : "6ta";
    case 7:
      return deporte === "tenis" ? "D" : "7ma";
    case 7:
      return deporte === "tenis" ? "" : "8va";
    default:
      return "";
  }
}

export function printEstadoTorneo(estado) {
  switch (estado) {
    case "inscripciones-abiertas":
      return "Inscripciones abiertas";
    case "inscripciones-cerradas":
      return "Inscripciones cerradas";
    case "en-curso":
      return "En Curso";
    case "terminado":
      return "Terminado";
    case "suspendido":
      return "Suspendido";
    default:
      return "";
  }
}

export function establecerAcciones(torneo, navigate, dispatch) {
  switch (torneo.estado) {
    case "inscripciones-abiertas":
      return [
        {
          titulo: "Ver Inscriptos",
          onClick: () => {
            dispatch({ type: "TORNEOS_SELECTED", payload: torneo });
            navigate(`/torneos/inscriptos/${torneo.id}`);
          },
        },
        {
          titulo: "Borrar Torneo",
          onClick: () => {
            dispatch({ type: "TORNEOS_SELECTED", payload: torneo });
            dispatch({ type: "TORNEOS_CONFIRMACION_BORRAR", payload: true });
          },
        },
      ];
    case "inscripciones-cerradas":
      return [
        {
          titulo: "Ver Inscriptos",
          onClick: () => {
            dispatch({ type: "TORNEOS_SELECTED", payload: torneo });
            navigate(`/torneos/inscriptos/${torneo.id}`);
          },
        },
      ];
    case "en-curso":
      return [
        {
          titulo: "Ver/Editar cuadro",
          onClick: () => {
            navigate(`/torneos/inscriptos/${torneo.id}`);
          },
        },
        {
          titulo: "Próximos Partidos",
          onClick: () => {},
        },
        {
          titulo: "Noticias",
          onClick: () => {},
        },
      ];
    case "terminado":
      return [
        {
          titulo: "Cuadro",
          onClick: () => {},
        },
        {
          titulo: "Ganadores",
          onClick: () => {},
        },
        {
          titulo: "Noticias",
          onClick: () => {},
        },
      ];
    case "suspendido":
      return [
        {
          titulo: "Cuadro",
          onClick: () => {},
        },
        {
          titulo: "Ganadores",
          onClick: () => {},
        },
        {
          titulo: "Noticias",
          onClick: () => {},
        },
      ];
    default:
      return [];
  }
}
