import React from "react";
import { Grid, Button, Typography } from "@mui/material";

const ErrorGet = ({ titulo, accion }) => {
  return (
    <>
      <Grid container direction="column" alignItems="center">
        <Grid item sx={{ m: "4em 0 2em 0" }}>
          <Typography variant="h4">{titulo}</Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={accion}>
            Intentar de nuevo
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ErrorGet;
