import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Avatar, IconButton, Slider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const AudioPlayer = ({ urlAudio, urlPhoto, time, id, tracks }) => {
  // State
  const [trackIndex, setTrackIndex] = useState(id);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  // Refs
  const audioRef = useRef(new Audio(urlAudio));
  const intervalRef = useRef();
  const isReady = useRef(false);

  // Destructure for conciseness
  const { duration } = audioRef.current;

  // Handles cleanup and setup when changing tracks // deberia cambiar cuando se cambia el index
  useEffect(() => {
    audioRef.current.pause();

    audioRef.current = new Audio(urlAudio);
    setTrackProgress(audioRef.current.currentTime);

    if (isReady.current) {
      // audioRef.current.play();
      setIsPlaying(true);
      startTimer();
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true;
    }
  }, []);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);
  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        clearInterval(intervalRef.current);
        setTrackProgress((audioRef.current.value = 0));
        setIsPlaying(false);
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };

  const handleChange = (value) => {
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const handlePlayPause = () => {
    let play = !isPlaying;
    setIsPlaying(play);
    if (play) {
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  };

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  return (
    <div style={container}>
      <div style={top}>
        <IconButton onClick={handlePlayPause}>
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        <p style={{ fontSize: 13 }}>{calculateTime(trackProgress)}</p>
        <Slider
          step={1}
          value={trackProgress}
          min={"0"}
          max={duration}
          color="warning"
          style={{ minWidth: 100, marginRight: 5, marginLeft: 5 }}
          onChange={(e) => handleChange(e.target.value)}
        />
        <p style={{ fontSize: 13 }}>
          {duration
            ? duration && !isNaN(duration) && calculateTime(duration)
            : "00:00"}
        </p>
        <Avatar src={urlPhoto} style={{ marginLeft: 5 }} />
      </div>
      <p style={timeStyle}>{time}</p>
    </div>
  );
};
const container = {
  display: "flex",
  flexDirection: "column",
};
const top = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
const timeStyle = {
  margin: 0,
  padding: 2,
  display: "flex",
  justifyContent: "flex-end",
  fontSize: 10,
  marginRight: 8,
};

export default AudioPlayer;
