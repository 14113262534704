import firebase from "firebase";
import servidor from "../../api/servidor";
import { Types } from "../../reducers/chatsReducer";
import app from "../../Firebase";
export const insertMessage = (chatId, message, callback) => {
  try {
    let chatRef = app.database().ref(`chats/${chatId}`);
    chatRef.push({
      texto: message.texto,
      audio: message.audio,
      tipoMensaje: message.tipoMensaje,
      usuario: message.usuario,
      createdAt: firebase.database.ServerValue.TIMESTAMP,
      visto: [message.usuario._id],
      enviado: true,
      recibido: true,
    });
    callback(true);
  } catch (error) {
    alert("no se agrego ", error);
  }
};
export const myChats = (etiqueta) => async (dispatch) => {
  dispatch({ type: Types.ERROR_CHAT, payload: false });
  dispatch({ type: Types.LOADING_CHAT, payload: true });
  try {
    let respuesta;
    if (etiqueta === undefined || etiqueta.length === 0) {
      respuesta = await servidor.get(
        `/chats/club?&sort=-fechaUltimaModificacion`
      );
    } else {
      respuesta = await servidor.get(
        `/chats/club?sort=-fechaUltimaModificacion`
      );
    }
    let chatList = respuesta.data.data;

    dispatch({ type: Types.GET_CHAT, payload: chatList });
  } catch (error) {
    console.log(error.response.data);
    dispatch({ type: Types.ERROR_CHAT, payload: true });
  }
  dispatch({ type: Types.LOADING_CHAT, payload: false });
};
export const updateChatInServer = (chatId) => async () => {
  try {
    await servidor.patch(`/chats/${chatId}/ultimoMensaje`);
  } catch (err) {}
};
export const updateListChat = (nuevoOrden) => async (dispatch) => {
  dispatch({ type: Types.LOADING_CHAT, payload: true });
  dispatch({ type: Types.GET_CHAT, payload: nuevoOrden });
  dispatch({ type: Types.LOADING_CHAT, payload: false });
};
export const showLastMessage = (chatId, callback) => async (dispatch) => {
  try {
    const chatRef = await app.database().ref(`chats/${chatId}`);

    await chatRef.limitToLast(1).on("value", (snapshot) => {
      const valor = snapshot.val();
      if (valor !== null) {
        const mensaje = valor[Object.keys(valor)[0]];

        if ((!mensaje.texto && !mensaje.audio) || !mensaje.usuario) return;
        callback(mensaje);
      }
    });
  } catch (e) {
    console.log(e);
  }
};
