import React, { useEffect, useState } from "react";
import { Grid, Typography, CircularProgress, Button } from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activarMetodoPago } from "../../actions/cliente";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fetchLlamado, setFetchLlamado] = useState(false);
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");

  const cargando = useSelector((state) => state.cliente.cargando);
  const error = useSelector((state) => state.cliente.error);

  useEffect(() => {
    if (sessionId && !fetchLlamado && !cargando) {
      dispatch(activarMetodoPago(sessionId));
      setFetchLlamado(true);
    }

    if (!sessionId && fetchLlamado && !cargando) {
      navigate("/mi-club");
    }
  }, [sessionId]);

  return (
    <Grid
      container
      sx={{ height: "100vh", width: "100%" }}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {sessionId && fetchLlamado && !cargando ? (
        !error ? (
          <>
            <Typography
              h2
              color="common.negroClaro3"
              sx={{ marginTop: "3em" }}
              textAlign="center"
            >
              Cuenta Activada! Ya podés publicar tu primer turno!
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/turnos/crearturno")}
              sx={{ marginTop: "3em" }}
            >
              Publicar Turno
            </Button>
          </>
        ) : (
          <Typography
            h2
            color="common.negroClaro3"
            sx={{ marginTop: "3em" }}
            textAlign="center"
          >
            Ocurrió un error activando la cuenta... contactarse con soporte
          </Typography>
        )
      ) : (
        <>
          <CircularProgress />
          <Typography
            h2
            color="common.negroClaro3"
            sx={{ marginTop: "3em" }}
            textAlign="center"
          >
            Activando cuenta de club
          </Typography>
        </>
      )}
    </Grid>
  );
};

export default Checkout;
