import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

const CustomModal = ({
  title,
  showModal,
  setShowModal,
  body,
  apply,
  disabled,
  onClickCancel,
}) => {
  return (
    <Dialog open={showModal} onClose={() => setShowModal(false)}>
      <DialogTitle sx={titleStyle}>{title}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions>
        <Button
          disabled={disabled}
          onClick={() => {
            setShowModal(false);
            if (onClickCancel) {
              onClickCancel();
            }
          }}
        >
          Cancelar
        </Button>
        {apply && (
          <Button variant="contained" disabled={disabled} onClick={apply}>
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
const titleStyle = {
  display: "flex",
  justifyContent: "center",
};

export default CustomModal;
