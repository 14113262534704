import React from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import FormularioStaff from "./FormularioStaff";
import TituloPantalla from "../Titulos/TituloPantalla";

const EditarUsuario = () => {
  const { id } = useParams();

  return (
    <Box className="main">
      <TituloPantalla titulo="Editar Colaborador" />
      <FormularioStaff accion="editar" id={id} />
    </Box>
  );
};

export default EditarUsuario;
