import TuneIcon from "@mui/icons-material/Tune";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addDays, format } from "date-fns";
import deLocale from "date-fns/locale/es";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTurnos } from "../../actions/turnos";
import TituloPantalla from "../Titulos/TituloPantalla";
import CustomTable from "./CustomTable";
import ErrorGet from "./ErrorGet";
import ModalFilter from "./ModalFilter";

const Pasados = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const turnos = useSelector((state) => state.turnos.data);
  const error = useSelector((state) => state.turnos.error);
  const cargando = useSelector((state) => state.turnos.cargando);
  const canchas = useSelector((state) => state.clubes.miClub.canchas);

  const [fecha, setFecha] = useState(addDays(new Date(), -1));
  const [verTodos, setVerTodos] = useState(true);
  const [showModal, setShowModal] = useState(false);

  //filters
  const [time, setTime] = useState("");
  const [slot, setSlot] = useState("Todas");
  const [type, setType] = useState("");
  const [price, setPrice] = useState([500, 10000]);

  useEffect(() => {
    fetchTurnos();
  }, [verTodos]);

  const fetchTurnos = () => {
    const slotId = slot !== "Todas" ? JSON.parse(slot)._id : "";
    const timeValue = time ? format(time, "HH:mm") : "";
    if (verTodos) {
      dispatch(getTurnos("", price, timeValue, slotId, type, true));
    } else {
      dispatch(getTurnos(fecha, price, timeValue, slotId, type, true));
    }
  };

  const handleVerChange = (event) => {
    setVerTodos(event.target.checked);
  };
  const columns = [
    "Fecha",
    "Hora Inicio",
    " Hora Final",
    "Cancha",
    "Precio",
    "Estado",
    "Reservado por",
  ];
  return (
    <>
      <ModalFilter
        showModal={showModal}
        setShowModal={setShowModal}
        enableReservations={true}
        time={time}
        setTime={setTime}
        slot={slot}
        setSlot={setSlot}
        type={type}
        setType={setType}
        price={price}
        setPrice={setPrice}
        handleUpdate={fetchTurnos}
      />

      <Box className="main">
        <TituloPantalla titulo="Turnos Pasados" />
        <Grid
          className="main"
          container
          item
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid container direction="row" alignItems="center" spacing={12}>
              <Grid item>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={deLocale}
                >
                  <MobileDatePicker
                    label="Fecha"
                    value={fecha}
                    disabled={verTodos}
                    inputFormat="dd/MM/yyyy"
                    minDate={addDays(new Date(), -365)}
                    maxDate={addDays(new Date(), -1)}
                    views={["year", "month", "day"]}
                    onChange={(newValue) => {
                      setFecha(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Ver todos"
                    checked={verTodos}
                    onChange={handleVerChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            sx={{ mr: "2em", mt: { xs: "1.5em", md: 0 }, p: 5 }}
            justifyContent={{ xs: "space-between", md: "flex-end" }}
            display="flex"
          >
            <Button
              variant="contained"
              sx={buttonStyle}
              onClick={() => setShowModal(true)}
            >
              Filtros <TuneIcon sx={{ marginLeft: 5 }} />
            </Button>

            <Button
              variant="contained"
              onClick={() => navigate("/turnos/crearturno")}
            >
              Crear nuevo turno
            </Button>
          </Grid>
        </Grid>
        {error ? (
          <ErrorGet titulo={error} accion={fetchTurnos} />
        ) : cargando ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mt: "3em" }}
          >
            <CircularProgress color="primary" />
          </Grid>
        ) : turnos.length === 0 ? (
          <Grid container sx={{ mt: "4em" }} justifyContent="center">
            <Typography variant="h4" color="common.negroClaro2">
              No hubo turnos publicados este día...
            </Typography>
          </Grid>
        ) : (
          <CustomTable tableHead={columns} data={turnos} isHistory={true} />
        )}
      </Box>
    </>
  );
};
const buttonStyle = {
  backgroundColor: "#f0f2f5",
  color: "#000",
  "&:hover": { backgroundColor: "#ffeccc" },
  marginRight: "1em",
};
export default Pasados;
