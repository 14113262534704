import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { getCheckout } from "../../actions/cliente";
import Notice from "../Avisos/Notice";

const PermisosProtected = ({ permitido, action = false }) => {
  const dispatch = useDispatch();
  const cargandoCheckout = useSelector(
    (state) => state.cliente.cargandoCheckout
  );
  const session = useSelector((state) => state.cliente.session);

  useEffect(() => {
    if (session && session.url) {
      window.location.href = session.url;
      dispatch({ type: "CLIENTE_CHECKOUT_SESSION", payload: null });
    }
  }, [session]);

  const handleCheckout = () => {
    dispatch(getCheckout());
  };

  if (!permitido) {
    const title = action
      ? "Activá tu método de pago"
      : "No tienes permitido entrar en esta sección";
    return (
      <Box className="main">
        <Notice
          title={title}
          hasButton={action}
          titleButton={"Activar"}
          loading={cargandoCheckout}
          onClick={handleCheckout}
        />
      </Box>
    );
  }

  return <Outlet />;
};

export default PermisosProtected;
