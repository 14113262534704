import { useEffect } from "react";
import { getTurno } from "../../actions/turnos";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../Loaders/Loader";
import TituloPantalla from "../Titulos/TituloPantalla";
import {
  Grid,
  Tabs,
  Tab,
  Box,
  Paper,
  Container,
  Avatar,
  Typography,
  TextField,
} from "@mui/material";
import InformacionGeneral from "../Club/InformacionGeneral";
import { LoadingButton } from "@mui/lab";
import { bucketURL } from "../../api/urls";
import TabRow from "../Club/TabRow";
import { printNombreCancha } from "../../Handle/Handle";
import format from "date-fns/format";

const TurnoDetalle = () => {
  const dispatch = useDispatch();
  const { turnDetail, cargando } = useSelector((state) => state.turnos);
  const canchas = useSelector((state) => state.clubes.miClub.canchas);

  const { turnoId } = useParams();
  const club = useSelector((state) => state.clubes);

  useEffect(() => {
    dispatch(getTurno(turnoId));
  }, []);

  //console.log("id", turnoId);
  console.log("turno", turnDetail);
  const calcSaldo = (data) => {
    if (data.disponible) {
      return "-";
    }
    if (!data.reservas[0].pagado && !data.reservas[0].señado) {
      return `$ ${data.precio}`;
    }
    if (data.reservas[0].pagado) {
      return "0";
    }
    if (data.reservas[0].señado) {
      const saldo =
        data.precio - data.precio * (data.reservas[0].comision / 100);
      return `$ ${saldo}`;
    }
  };
const padelImg = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzthFYfGDV-lkXBaQqq_TOuqFXJhMb8wiuOg&usqp=CAU'
const tenisImg = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9Nd9-tK4hmUQqE3kSVt8PpNBWVaSSWCljNA&usqp=CAU'
const imageAvatar = turnDetail.deporte==='padel'? padelImg: tenisImg
  return (
    <Box className="main">
      <TituloPantalla titulo="Detalle del Turno" />
      {cargando ? (
        <Loader />
      ) : (
        <Paper sx={{ maxWidth: "xs" }}>
          <Container component="main" maxWidth="sm">
            <Box sx={root}>
              <Avatar
                sx={{ m: 1, width: "8em", height: "8em" }}
                src={imageAvatar}
              />

              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Detail
                    leftComponent={
                      <Typography sx={{ pl: 10 }}>Cancha</Typography>
                    }
                    rightComponent={
                      <Typography>
                        {printNombreCancha(canchas, turnDetail.cancha)}
                      </Typography>
                    }
                  />
                  <div style={divider} />
                  <Detail
                    leftComponent={
                      <Typography sx={{ pl: 10 }}>Fecha</Typography>
                    }
                    rightComponent={
                      <Typography>
                        {format(new Date(turnDetail.fechaInicio), "dd/MM/y")}
                      </Typography>
                    }
                  />
                  <div style={divider} />
                  <Detail
                    leftComponent={
                      <Typography sx={{ pl: 10 }}>Hora Inicio</Typography>
                    }
                    rightComponent={
                      <Typography>
                        {format(new Date(turnDetail.fechaInicio), "k:mm a")}
                      </Typography>
                    }
                  />
                  <div style={divider} />
                  <Detail
                    leftComponent={
                      <Typography sx={{ pl: 10 }}>Hora Final</Typography>
                    }
                    rightComponent={
                      <Typography>
                        {format(new Date(turnDetail.fechaFinal), "k:mm a")}
                      </Typography>
                    }
                  />
                  <div style={divider} />
                  <Detail
                    leftComponent={
                      <Typography sx={{ pl: 10 }}>Precio</Typography>
                    }
                    rightComponent={
                      <Typography>${turnDetail.precio}</Typography>
                    }
                  />
                  <div style={divider} />
                  <Detail
                    leftComponent={
                      <Typography sx={{ pl: 10 }}>Estado</Typography>
                    }
                    rightComponent={
                      <Typography>
                        {turnDetail.disponible ? "Disponible" : "Reservado"}
                      </Typography>
                    }
                  />
                  <div style={divider} />

                  <Detail
                    leftComponent={
                      <Typography sx={{ pl: 10 }}>Reservado por</Typography>
                    }
                    rightComponent={
                      <Typography>
                        {turnDetail.disponible && "-"}
                        {!turnDetail.disponible &&
                          turnDetail?.reservas?.[0]?.usuario?.nombreCompleto}
                      </Typography>
                    }
                  />
                  <div style={divider} />

                  <Detail
                    leftComponent={
                      <Typography sx={{ pl: 10 }}>Saldo</Typography>
                    }
                    rightComponent={
                      <Typography>{calcSaldo(turnDetail)}</Typography>
                    }
                  />
                  <div style={divider} />
                </Grid>

                {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 20 }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => {}}
                    loading={cargando}
                  >
                    Confirmar datos
                  </LoadingButton>
                </Box> */}
              </Box>
            </Box>
          </Container>
        </Paper>
      )}
    </Box>
  );
};
const Detail = ({ leftComponent, rightComponent }) => {
  return (
    <Box sx={container}>
      <Box sx={left}>{leftComponent}</Box>
      <Box sx={right}>{rightComponent}</Box>
    </Box>
  );
};
const root={
  marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
}
const container = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
};
const left = {
  width: "40%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};

const right = {
  width: "60%",
  display: "flex",
  justifyContent: "flex-end",
  mr: "2em",
  alignItems: "center",
};
const divider = {
  width: "100%",
  height: 1,
  backgroundColor: "#DDDDDD",
  margin: 10,
};
export default TurnoDetalle;
