export const TYPES = {
  FIREBASE_AUTH:'FIREBASE_GET',
  FIREBASE_ERROR:'FIREBASE_ERROR',
  FIREBASE_LOADING:'FIREBASE_CARGANDO'
}

const INITIAL_STATE = {
  login: false,
  error: false,
  cargando: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.FIREBASE_AUTH:
      return { ...state, login: action.payload };
    case TYPES.FIREBASE_ERROR:
      return { ...state, error: action.payload };
    case TYPES.FIREBASE_LOADING:
      return { ...state, cargando: action.payload };
    default:
      return state;
  }
};
