const INITIAL_STATE = {
  data: null,
  cargando: false,
  error: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "USUARIO_DATA":
      return { ...state, data: action.payload };
    case "USUARIO_CARGANDO":
      return { ...state, cargando: action.payload };
    case "USUARIO_ERROR":
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
