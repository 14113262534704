import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Slider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { primeraLetraMayuscula } from "../../Handle/Handle";
import { delay } from "../../utils/helperFunctions";
import CustomModal from "../Modals/CustomModal";

const FilterModal = ({ showModal, setShowModal }) => {
  //state
  const [cargando, setCargando] = useState(false);
  const [price, setPrice] = useState([500, 5000]);
  const [commission, setCommission] = useState([0, 8]);
  const [slot, setSlot] = useState("");
  //redux
  const club = useSelector((state) => state.clubes.miClub);
  const handleApply = async () => {
    setCargando(true);
    //do something
    await delay(1000);
    setCargando(false);
    setShowModal(false);
  };

  return (
    <CustomModal
      setShowModal={setShowModal}
      showModal={showModal}
      title={"Elige tus filtros"}
      apply={handleApply}
      disabled={cargando}
      body={
        <>
          <Box sx={{ minWidth: 230, mt: 10 }}>
            <Typography sx={centerText}>Precio</Typography>

            <Slider
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              min={500}
              max={10000}
              defaultValue={[3000, 7000]}
              disableSwap
            />
          </Box>
          <Box sx={pricesStyle}>
            <Typography>$ {price[0]}</Typography>
            <Typography>$ {price[1]}</Typography>
          </Box>

          <Box sx={pushTop}>
            <Typography sx={centerText}>Comisión</Typography>

            <Slider
              value={commission}
              onChange={(e) => setCommission(e.target.value)}
              min={0}
              max={15}
              disableSwap
            />
          </Box>
          <Box sx={pricesStyle}>
            <Typography>% {commission[0]}</Typography>
            <Typography>% {commission[1]}</Typography>
          </Box>
          <Box sx={pushTop}>
            <Typography sx={centerText}>Canchas</Typography>
            <FormControl fullWidth sx={{ minWidth: 230, mt: 10 }} size="small">
              <Select
                name="cancha"
                required
                labelId="cancha-label"
                id="cancha-select"
                value={slot}
                onChange={(e) => setSlot(e.target.value)}
              >
                {club.canchas.map((c, i) => (
                  <MenuItem key={i} value={c}>
                    {primeraLetraMayuscula(c.nombre)} -
                    {primeraLetraMayuscula(c.deporte)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {cargando ? (
            <Box sx={loaderStyle}>
              <CircularProgress />
            </Box>
          ) : null}
        </>
      }
    />
  );
};

const pushTop = {
  mt: 10,
};
const centerText = {
  textAlign: "center",
};
const pricesStyle = {
  display: "flex",
  justifyContent: "space-between",
};
const loaderStyle = {
  display: "flex",
  justifyContent: "center",
  mt: 5,
};

export default FilterModal;
