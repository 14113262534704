import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import EditIcon from "@mui/icons-material/Edit";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Avatar,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import LayoutTabs from "./LayoutTabs";
import CustomModal from "../Modals/CustomModal";
import TabRow from "./TabRow";
import { delay } from "../../utils/helperFunctions";
import { useSelector, useDispatch } from "react-redux";
import { actualizarClub } from "../../actions/clubes";

const InformacionGeneral = () => {
  //redux
  const dispatch = useDispatch();
  const club = useSelector((state) => state.clubes.miClub);
  const cargando = useSelector((state) => state.clubes.cargando);
  //state
  const [showModal, setShowModal] = useState(false);
  const [arrayModal, setArrayModal] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [arrayPhones, setArrayPhones] = useState(club.contactos);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (fetched && !cargando) {
      setNewValue("");
      setFetched(false);
      setShowModal(false);
      setArrayModal(false);
      setArrayPhones(club.contactos);
    }
  }, [cargando, fetched]);

  const handleUpdate = (e) => {
    switch (e) {
      case club.nombre:
        dispatch(actualizarClub({ nombre: newValue }));
        break;
      case club.email:
        dispatch(actualizarClub({ email: newValue }));
        break;
      case club.descripcion:
        dispatch(actualizarClub({ descripcion: newValue }));
        break;
    }
    setFetched(true);
  };

  const handlePhonesUpdate = () => {
    dispatch(actualizarClub({ contactos: arrayPhones }));
    setFetched(true);
  };

  //
  const titulos = (e) => {
    switch (e) {
      case club.nombre:
        return "Cambia el nombre de tu club";
      case club.email:
        return "Cambia el e-mail de tu club";
      case club.descripcion:
        return "Cambia la descripción de tu club";
    }
  };

  const openModal = (e) => {
    if (e === club.nombre || e === club.email || e === club.descripcion) {
      return true;
    }
    return;
  };

  const deleteItem = (e) => {
    const newArray = arrayPhones.filter((f) => f !== e);
    setArrayPhones(newArray);
  };

  const handleAddPhone = () => {
    let arrayPhonesCopy = [...arrayPhones];
    arrayPhonesCopy = [...arrayPhones, `+54${phoneNumber}`];
    setPhoneNumber("");
    setArrayPhones(arrayPhonesCopy);
  };
  // Modal Content
  const modalBody = (
    <Box>
      <TextField
        sx={{ display: "flex" }}
        id="outlined-multiline-static"
        multiline={showModal === club.descripcion}
        rows={showModal === club.descripcion && 4}
        size={"small"}
        value={newValue}
        onChange={(e) => setNewValue(e.target.value)}
        autoFocus
      />
      {cargando ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          <CircularProgress />
        </Box>
      ) : null}
    </Box>
  );

  const modalBody2 = (
    <Box sx={containerModal}>
      <Box sx={inputStyle}>
        <TextField
          value={phoneNumber}
          size={"small"}
          sx={{ width: "90%" }}
          color="secondary"
          onChange={(e) =>
            setPhoneNumber(e.target.value.replace(/[^0-9]/g, ""))
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+54</InputAdornment>
            ),
          }}
        />
      </Box>
      {arrayPhones?.map((e, i) => (
        <Box sx={phoneItemStyle}>
          <TextField
            defaultValue={e}
            size={"small"}
            disabled
            variant="standard"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <IconButton onClick={() => deleteItem(e)}>
                  <DoDisturbOnIcon
                    color="secondary"
                    sx={{ fontSize: "1.4em" }}
                  />
                </IconButton>
              ),
            }}
          />
        </Box>
      ))}
      <Box alignSelf={"center"}>
        <IconButton
          disabled={phoneNumber === ""}
          color="primary"
          onClick={handleAddPhone}
        >
          <AddCircleIcon sx={{ fontSize: "3em" }} />
        </IconButton>
      </Box>
      {cargando ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          <CircularProgress />
        </Box>
      ) : null}
    </Box>
  );
  return (
    <>
      {/*========CONTENT======== */}
      <LayoutTabs>
        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>Nombre</Typography>
          }
          centerComponent={
            <Typography color={"#424242"}>{club.nombre} </Typography>
          }
          rightComponent={
            <IconButton
              onClick={() => {
                setNewValue(club.nombre);
                setShowModal(club.nombre);
              }}
            >
              <EditIcon />
            </IconButton>
          }
        />
        <div style={divider} />
        {/* <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>Logo</Typography>
          }
          centerComponent={<Avatar src={club.logo} sx={logoStyle} />}
        />
        <div style={divider} />
        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>Portada</Typography>
          }
          centerComponent={<img src={club.portada} style={portadaStyle} />}
        />
        <div style={divider} /> */}
        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>E-mail</Typography>
          }
          centerComponent={
            <Typography color={"#424242"}>{club.email} </Typography>
          }
          rightComponent={
            <IconButton
              onClick={() => {
                setNewValue(club.email);
                setShowModal(club.email);
              }}
            >
              <EditIcon />
            </IconButton>
          }
        />
        <div style={divider} />
        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>Descripción</Typography>
          }
          centerComponent={
            <Typography color={"#424242"}>{club.descripcion}</Typography>
          }
          rightComponent={
            <IconButton
              onClick={() => {
                setNewValue(club.descripcion);
                setShowModal(club.descripcion);
              }}
            >
              <EditIcon />
            </IconButton>
          }
        />
        <div style={divider} />
        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>Ubicación</Typography>
          }
          centerComponent={
            <Typography color={"#424242"}>
              {`${club?.ubicacion?.direccion} - ${club?.ubicacion?.ciudad} - ${club?.ubicacion?.provincia} - ${club?.ubicacion?.pais}`}
            </Typography>
          }
        />
        <div style={divider} />
        <TabRow
          leftComponent={
            <Typography color={"common.negroClaro3"}>Contactos</Typography>
          }
          centerComponent={
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {club?.contactos?.map((e) => (
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  mb={2}
                >
                  <PhoneIcon sx={phoneStyle} />
                  <Typography>{e}</Typography>
                </Box>
              ))}
            </Box>
          }
          rightComponent={
            <IconButton onClick={() => setArrayModal(true)}>
              <EditIcon />
            </IconButton>
          }
        />
      </LayoutTabs>

      {/*========MODAL STRING======== */}
      <CustomModal
        setShowModal={setShowModal}
        showModal={openModal(showModal)}
        title={titulos(showModal)}
        body={modalBody}
        apply={async () => {
          handleUpdate(showModal);
        }}
      />

      {/*========MODAL ARRAY======== */}
      <CustomModal
        title={"Modifica tus números"}
        showModal={arrayModal}
        setShowModal={setArrayModal}
        body={modalBody2}
        apply={handlePhonesUpdate}
      />
    </>
  );
};
const divider = {
  width: "100%",
  height: 1,
  backgroundColor: "#DDDDDD",
  margin: 10,
};
const logoStyle = {
  width: "4em",
  height: "4em",
  border: 3,
  borderColor: "common.principal",
};
const portadaStyle = {
  width: "6em",
  height: "4em",
  borderRadius: "2%",
  padding: 3,
  backgroundColor: "#000",
  objectFit: "cover",
};
const phoneStyle = {
  backgroundColor: "#f5f5f5",
  borderRadius: 1,
  padding: 3,
  mr: 3,
};

const containerModal = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
const inputStyle = {
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  mb: 6,
  mt: 4,
};
const phoneItemStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  mb: 6,
};
export default InformacionGeneral;
