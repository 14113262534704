import React from "react";
import { Box } from "@mui/material";
import FormularioStaff from "./FormularioStaff";
import TituloPantalla from "../Titulos/TituloPantalla";

const CrearUsuario = () => {
  return (
    <Box className="main">
      <TituloPantalla titulo="Nuevo Colaborador" />
      <FormularioStaff accion="crear" />
    </Box>
  );
};

export default CrearUsuario;
