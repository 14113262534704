const INITIAL_STATE = {
  data: [],
  selected: null,
  error: "",
  errorBorrar: "",
  cargando: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "PERSONAL_DATA":
      return { ...state, data: action.payload };
    case "PERSONAL_SELECTED":
      return { ...state, selected: action.payload };
    case "PERSONAL_BORRAR":
      return {
        ...state,
        data: [...state.data.filter((e) => e.id !== action.payload)],
      };
    case "PERSONAL_ERROR":
      return { ...state, error: action.payload };
    case "PERSONAL_ERROR_BORRAR":
      return { ...state, errorBorrar: action.payload };
    case "PERSONAL_CARGANDO":
      return { ...state, cargando: action.payload };
    default:
      return state;
  }
};
