import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const CreacionExitosa = ({
  open,
  onClose,
  titulo,
  texto,
  setOpen,
  textButton,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-creacion-exitosa-titulo"
      aria-describedby="modal-creacion-exitosa-descripcion"
    >
      <DialogTitle id="modal-creacion-exitosa-titulo">{titulo}</DialogTitle>

      <DialogContent>
        <DialogContentText id="modal-creacion-exitosa-descripcion">
          {texto}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {textButton ? <Button onClick={onClose}>{textButton}</Button> : null}
        <Button onClick={() => setOpen(false)}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreacionExitosa;
