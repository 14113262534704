import DeleteIcon from "@mui/icons-material/Delete";
import ThreePIcon from "@mui/icons-material/ThreeP";
import {
  Box,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { printNombreCancha } from "../../Handle/Handle";
import { delay } from "../../utils/helperFunctions";
import ConfirmarBorrar from "../Modals/ConfirmarBorrar";
import PaginationMobile from "./PaginationMobile";
import { borrarTurno } from "../../actions/turnos";

const CustomTable = ({ data, tableHead, isHistory = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //redux
  const canchas = useSelector((state) => state.clubes.miClub.canchas);
  const selected = useSelector((state) => state.turnos.selected);
  //state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [cargando, setCargando] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const calcSaldo = (data) => {
    if (data.disponible) {
      return "-";
    }
    if (!data.reservas[0].pagado && !data.reservas[0].señado) {
      return `$ ${data.precio}`;
    }
    if (data.reservas[0].pagado) {
      return "0";
    }
    if (data.reservas[0].señado) {
      const saldo =
        data.precio - data.precio * (data.reservas[0].comision / 100);
      return `$ ${saldo}`;
    }
  };

  const handleClickBorrar = (turno) => {
    dispatch({ type: "TURNOS_SELECTED", payload: turno });
    setShowModal(true);
  };

  const handleDelete = async () => {
    dispatch(borrarTurno(selected._id));
    dispatch({ type: "TURNOS_SELECTED", payload: null });
    setShowModal(false);
  };

  const handleChat = (id) => {
    //do something
    navigate(`/chats/${id}`);
  };
  return (
    <>
      {/*===========MOBILE TABLE=========== */}
      {(rowsPerPage > 0
        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : data
      ).map((row) => (
        <>
          <List sx={root} disablePadding>
            <ListItem>
              <Box sx={container}>
                {/*=====LEFT=====*/}

                <Box sx={left}>
                  <Box mb={2}>
                    <Typography
                      variant="title2"
                      color={!row.disponible ? "#ce8401" : "#2d992c"}
                      sx={{
                        ...customChipStyle,
                        backgroundColor: !row.disponible
                          ? "#ffeccc"
                          : "#cdffcd",
                      }}
                    >
                      {`● ${row.disponible ? "Disponible" : "Reservado"}`}
                    </Typography>
                  </Box>

                  <Typography variant="title" color="common.negroClaro2">
                    {row?.reservas &&
                      row.reservas[0] &&
                      row.reservas[0].usuario.nombreCompleto}
                  </Typography>
                  <Typography variant="title" color="common.negroClaro2">
                    {printNombreCancha(canchas, row.cancha)}
                  </Typography>
                </Box>
                {/*=====RIGHT=====*/}
                <Box sx={right}>
                  <Box sx={rightItem} style={{ minWidth: 50 }}>
                    <Typography variant="title" color="common.negroClaro2">
                      {format(new Date(row.fechaInicio), "k:mm a")}
                    </Typography>
                    <Typography variant="title" color="common.negroClaro2">
                      {format(new Date(row.fechaFinal), "k:mm a")}
                    </Typography>
                  </Box>

                  <Box sx={rightItem}>
                    <Typography variant="title" color="common.negroClaro2">
                      {format(new Date(row.fechaInicio), "dd/MM/y")}
                    </Typography>
                    <Typography variant="title" color="common.negroClaro2">
                      ${row.precio}
                    </Typography>
                  </Box>
                  <Box sx={deleteStyle}>
                    <IconButton aria-label="delete" size="small">
                      {!row.disponible ? (
                        <IconButton
                          size={"large"}
                          onClick={() => {
                            if (row.reservas && row.reservas.length > 0) {
                              handleChat(row.reservas[0].chat);
                            }
                          }}
                        >
                          <ThreePIcon />
                        </IconButton>
                      ) : null}

                      {!isHistory && row.disponible ? (
                        <IconButton
                          size={"small"}
                          onClick={() => handleClickBorrar(row)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : null}
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </ListItem>
          </List>
          <Divider sx={{ display: { sm: "flex", md: "none" } }} />
        </>
      ))}

      <PaginationMobile
        page={page}
        setPage={(e) => setPage(e)}
        numberOfItems={data?.length}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(e) => setRowsPerPage(e)}
      />

      {/**=============WEB TABLE=============== */}
      <TableContainer component={Paper} sx={tableContainerStyle}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead sx={{ backgroundColor: "#ffeccc" }}>
            <TableRow>
              {tableHead.map((element, index) => {
                return (
                  <TableCell key={index} align="center">
                    {element}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row) => (
              <TableRow key={row._id}>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ minWidth: "2em" }}
                >
                  {format(new Date(row.fechaInicio), "dd/MM/y")}
                </TableCell>
                <TableCell style={{ minWidth: "1em" }} align="center">
                  {format(new Date(row.fechaInicio), "k:mm a")}
                </TableCell>
                <TableCell style={{ minWidth: "1em" }} align="center">
                  {format(new Date(row.fechaFinal), "k:mm a")}
                </TableCell>
                <TableCell style={{ minWidth: "1em" }} align="center">
                  {printNombreCancha(canchas, row.cancha)}
                </TableCell>
                <TableCell style={{ minWidth: "1em" }} align="center">
                  ${row.precio}
                </TableCell>
                <TableCell style={{ minWidth: "2em" }} align="center">
                  <Chip
                    label={`● ${row.disponible ? "Disponible" : "Reservado"}`}
                    sx={{
                      fontSize: 14,
                      backgroundColor: !row.disponible ? "#ffeccc" : "#cdffcd",
                      color: !row.disponible ? "#ce8401" : "#2d992c",
                    }}
                  />
                </TableCell>

                <TableCell>
                  {!row.disponible ? (
                    <Box sx={reservadoPorStyle}>
                      <Typography>
                        {row?.reservas?.[0]?.usuario?.nombreCompleto}
                      </Typography>
                      {!isHistory ? (
                        <IconButton
                          size={"large"}
                          sx={{ ml: 5 }}
                          onClick={() => {
                            if (row.reservas && row.reservas.length > 0) {
                              handleChat(row.reservas[0].chat);
                            }
                          }}
                        >
                          <ThreePIcon />
                        </IconButton>
                      ) : null}
                    </Box>
                  ) : (
                    <Typography>-</Typography>
                  )}
                </TableCell>

                {!isHistory ? <TableCell>{calcSaldo(row)}</TableCell> : null}
                <TableCell style={{ minWidth: "3em" }} align="center">
                  {!isHistory && row.disponible ? (
                    <IconButton
                      size={"large"}
                      onClick={() => handleClickBorrar(row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ page }) => {
                  return `Pagina: ${page}`;
                }}
                backIconButtonProps={{
                  color: "default",
                }}
                nextIconButtonProps={{ color: "default" }}
                showFirstButton={true}
                showLastButton={true}
                labelRowsPerPage={<span>Filas:</span>}
                sx={paginationStyle}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ConfirmarBorrar
        open={showModal}
        handleCancelar={() => {
          dispatch({ type: "TURNOS_SELECTED", payload: null });
          setShowModal(false);
        }}
        titulo={"Borrar Turno"}
        texto={"¿Estás seguro que queres borrar este turno publicado?"}
        handleBorrar={handleDelete}
        cargando={cargando}
      />
    </>
  );
};
const tableContainerStyle = {
  display: { xs: "none", sm: "none", md: "flex" },
};
const paginationStyle = {
  ".MuiTablePagination-toolbar": {
    backgroundColor: "#fff",
  },
  ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
    color: "GrayText",
  },
};
const root = {
  display: { sm: "flex", md: "none" },
  backgroundColor: "#fff",
};
const container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
};
const left = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  //backgroundColor:'red'
};
const right = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
const rightItem = {
  display: "flex",
  flexDirection: "column",
  marginLeft: 5,
  alignItems: "center",
};
const deleteStyle = {
  display: "flex",
  marginLeft: 5,
  alignItems: "center",
  minWidth: 30,
  justifyContent: "center",
};
const customChipStyle = {
  backgroundColor: true ? "#cdffcd" : "#ffeccc",
  padding: 2,
  borderRadius: 5,
};
const reservadoPorStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};
export default CustomTable;
